<template>
    <v-row class="colcenter" style="background-color: white;">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <v-row class="colcenter">
                <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter" v-if="estadoWallet == 0">
                    <v-row class="colcenter">
                        <v-col cols="12" sm="12" md="10" lg="6" xl="6" class="colcenter">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="10" lg="8" xl="8" class="colcenter frame-60">
                                    <v-row class="colcenter">
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                            <div class="numero-de-cliente">Numero de cliente</div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                            <div class="clienten">{{ cliente.numero }}</div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="10" lg="8" xl="8" class="colcenter frame-61">
                                    <v-row class="colcenter">
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                            <div class="saldo">Saldo</div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                            <div class="monto">{{ cliente.saldo }}</div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                            <v-btn class="sm-size" large rounded @click="cambiaMenuWallet(7)"
                                                color="#1d2eff"><span><span
                                                        style="color:white;">RETIRAR</span></span></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>

                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="10" lg="6" xl="6" class="colcenter">
                            <v-row class="colcenter cuadroderbtn">
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter btnderup">
                                    <v-btn block large class="sm-size" rounded color="#04137f"
                                        @click="cambiaMenuWallet(9)"><span><span style="color:white">VER
                                                OFERTAS</span></span></v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter btnderup">
                                    <v-btn block large class="sm-size" rounded color="#04137f"
                                        @click="cambiaMenuWallet(4)"><span><span style="color:white">AGREGAR
                                                CUENTA</span></span></v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter btnderdown">
                                    <v-btn block large class="sm-size" rounded color="#04137f"
                                        @click="cambiaMenuWallet(3)"><span><span
                                                style="color:white">CARTOLA</span></span></v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter btnderdown">
                                    <v-btn block large class="sm-size" rounded color="#04137f"
                                        @click="cambiaMenuWallet(2)"><span><span style="color:white">ÚLTIMOS
                                                MOVIMIENTOS</span></span></v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter" v-if="estadoWallet == 7">
                    <v-row class="colcenter cuadroretiro">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-60-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="retirar-dinero">Retirar dinero</div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="clienten">Número de Cliente {{ cliente.numero }}</div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="nrocli">
                                        Selecciona la cuenta donde desear depositar tu retiro y luego indica el monto a
                                        transferir.
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-61-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <v-form ref="form2" v-model="validRetiro" style="width: 100%;">
                                        <v-row class="colcenter">
                                            <v-col cols="12" sm="10" md="8" lg="6" xl="6" >
                                                <div
                                                    style="border-radius: 21px; border: 1px solid #67676c; padding-top: 30px">
                                                    <div style="text-align: center; margin-bottom: 10px">
                                                        <span class="cabcuentasbancarias">Seleccione una Cuenta</span>
                                                    </div>
                                                    <v-expansion-panels style="z-index: inherit">
                                                        <v-radio-group v-model="selectCuenta" :rules="cuentaRules"
                                                            required @update:error="vcuenta">
                                                            <v-expansion-panel v-for="(item, index) in cuentasBancarias"
                                                                :key="index">
                                                                <v-expansion-panel-header>
                                                                    <div>
                                                                        <v-row>
                                                                            <v-col>
                                                                                <img alt="Avatar" class="bancosimg"
                                                                                    :src="`playground_assets/bancos/` + item.wcb_banco + `.png`" />
                                                                            </v-col>
                                                                            <v-col style="align-self: center;">
                                                                                <span class="bancotable"> {{
                                                                                    item.wcb_banco_nombre }}</span>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </div>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content style="text-align: left">
                                                                    <template>
                                                                        <div>
                                                                            <v-row >
                                                                                <v-col cols="12" sm="12">
                                                                                    <v-row v-for="(item2, index2) in item.cuentas" class="columnCuenta"
                                                                                    :key="index2">
                                                                                    <v-col cols="6" sm="6" md="6" lg="2" xl="2" class="colcenter">
                                                                                        <v-radio
                                                                                            :key="item2.wcb_numero"
                                                                                            :value="item2.wcb_numero"
                                                                                            color="azulsitio"
                                                                                            @click="selectBancoCuenta(item2.wcb_cuenta)">
                                                                                        </v-radio>
                                                                                    </v-col>
                                                                                    <v-col cols="6" sm="6" md="6" lg="3" xl="3" class="colcenter">
                                                                                        <span
                                                                                                            class="tipocuentatable">{{
                                                                                                                item2.wcb_tipo_cuenta_nombre
                                                                                                            }} </span>
                                                                                    </v-col>
                                                                                    <v-col cols="12" sm="12" md="10" lg="3" xl="3" class="colcenter">
                                                                                        <span
                                                                                                            class="numerocuentatable">{{
                                                                                                                item2.wcb_cuenta
                                                                                                            }}</span>
                                                                                    </v-col>
                                                                                    <v-col cols="6" sm="6" md="6" lg="2" xl="2" class="colcenter">
                                                                                        <div
                                                                                                            class="actioncuentatable">
                                                                                                            <v-icon
                                                                                                                class="ml-1 selectCuenta"
                                                                                                                @click="cambiaCuenta(item2)">
                                                                                                                mdi-pencil
                                                                                                            </v-icon>

                                                                                                        </div>
                                                                                    </v-col>
                                                                                    <v-col cols="6" sm="6" md="6" lg="2" xl="2" class="colcenter">
                                                                                        <div
                                                                                                            class="actioncuentatable">
                                                                                                            <v-icon
                                                                                                                class="ml-2 selectCuenta"
                                                                                                                @click="borrarCuenta(item2)">
                                                                                                                mdi-trash-can-outline
                                                                                                            </v-icon>

                                                                                                        </div>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                    <!-- <v-simple-table>
                                                                                        <template v-slot:default>
                                                                                            <tbody>
                                                                                                <tr v-for="(item2, index2) in item.cuentas"
                                                                                                    :key="index2"
                                                                                                    style="background-color: white !important">
                                                                                                    <td>
                                                                                                       
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        

                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </template>
                                                                                    </v-simple-table> -->
                                                                                </v-col>
                                                                            </v-row>
                                                                        </div>
                                                                    </template>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-radio-group>
                                                    </v-expansion-panels>

                                                </div>
                                            </v-col>
                                            <v-divider class="lines" vertical></v-divider>
                                            <v-col cols="12" sm="10" md="8" lg="6" xl="6">
                                                <div style="
                                                text-align: center;
                                                padding: 30px;
                                                border-style: 1px solid;
                                                border-radius: 21px;
                                                border: 1px solid rgb(103, 103, 108);
                                            ">
                                                    <div style="text-align: center; margin-bottom: 10px">
                                                        <span class="cabcuentasbancarias">Ingresa monto a retirar</span>
                                                    </div>
                                                    <v-simple-table>
                                                        <template v-slot:default>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <span class="cabretirartable">
                                                                            <v-icon small class="mr-2"
                                                                                color="azulsitio">
                                                                                mdi-alert-circle-outline
                                                                            </v-icon>
                                                                            Giro máximo permitido {{ cliente.saldo
                                                                            }}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <v-row class="colcenter">
                                                                            <v-col cols="1" col="1"></v-col>
                                                                            <v-col cols="9" col="9">
                                                                                <v-currency-field x-large ref="giro"
                                                                                    v-model="giro"
                                                                                    :rules="giroPermitidoRules" required
                                                                                    :placeholder="saldoWallet"
                                                                                    maxlength="10" color="rgb(56,0,151)"
                                                                                    :prefix="prefix"
                                                                                    class="centered-input classmonto"
                                                                                    @update:error="vgiro">
                                                                                </v-currency-field>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                    <div
                                                        style="text-align: center; margin-bottom: 10px; margin-top: 10px">
                                                        <span class="cabcuentasbancarias">Ingresa monto a retirar</span>
                                                    </div>
                                                </div>
                                                <div style="text-align: center; margin-bottom: 10px; margin-top: 30px">
                                                    <v-btn
                                                        style="text-transform: unset !important; padding: 27px; width: 50%"
                                                        rounded color="juanito" large @click="validateRetiro"
                                                        :disabled="!validRetiro">
                                                        <h3 style="color: white">Transferir</h3>
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>

                </v-col>
                <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter" v-if="estadoWallet == 2">
                    <v-row class="colcenter cuadroretiro">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-60-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="retirar-dinero">Número de Cliente {{ cliente.numero }}</div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="clienten">Saldo {{ cliente.saldo }}</div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="nrocli">
                                        Últimos movimientos
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-61-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                            <v-btn x-large rounded color="juanito" large @click="cambiaMenuWallet(7)">
                                                <span style="color: white">Retirar</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <template>
                                        <v-data-table :headers="headers" :items="registros" :items-per-page="5"
                                            class="elevation-1">
                                            <template v-slot:item.accion="{ item }">
                                                <v-icon large class="mr-2" @click="cargaModalMovimientos(item)">
                                                    mdi-tray-arrow-down
                                                </v-icon>

                                            </template>
                                        </v-data-table>
                                    </template>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">

                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>

                </v-col>
                <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter" v-if="estadoWallet == 3">
                    <v-row class="colcenter cuadroretiro">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-60-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="retirar-dinero">Cartola</div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="retirar-dinero">Número de Cliente {{ cliente.numero }}</div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="clienten">Saldo {{ cliente.saldo }}</div>
                                </v-col>

                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-61-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <template>
                                        <v-data-table :headers="headerscartola" :items="datoscartola"
                                            :items-per-page="5" class="elevation-1">
                                            <template v-slot:item.monto="{ item }">
                                                <span>{{ item.monto }}</span>
                                                <v-icon v-if="item.tipo == 1" class="ml-4" color="red">
                                                    mdi-arrow-top-right
                                                </v-icon>
                                                <v-icon v-if="item.tipo == 2" class="ml-4" color="success">
                                                    mdi-arrow-bottom-left
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.accion="{ item }">
                                                <v-icon large class="mr-2" @click="cargaModalCartola(item)">
                                                    mdi-tray-arrow-down
                                                </v-icon>

                                            </template>
                                        </v-data-table>
                                    </template>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <v-btn rounded color="juanito" x-large>
                                        <v-icon large class="mr-2" color="white"> mdi-file-download
                                        </v-icon>
                                        <span style="color: white">Descargar cartola</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>

                </v-col>
                <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter" v-if="estadoWallet == 4">
                    <v-row class="colcenter cuadroretiro">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-60-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="retirar-dinero">Registrar Cuenta Bancaria</div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="nrocli">
                                        Puedes ver el listado de tus cuentas registras y puedes agregar cuentas nuevas.
                                    </div>
                                </v-col>

                            </v-row>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-61-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <v-btn rounded color="juanito" class="sm-size" x-large @click="cambiaMenuWallet(5)">
                                        <v-icon large class="mr-2" color="white"> mdi-account-cog-outline
                                        </v-icon>
                                        <span style="color: white">Agregar cuenta bancaria</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-row class="colcenter">
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                            <div style="border-radius: 21px; border: 1px solid #67676c; padding: 30px">
                                                <div style="text-align: center; margin-bottom: 10px">
                                                    <span class="cabcuentasbancarias">Tus cuentas bancarias</span>
                                                </div>
                                                <v-expansion-panels style="z-index: inherit">
                                                    <v-expansion-panel v-for="(item, index) in cuentasBancarias"
                                                        :key="index">
                                                        <v-expansion-panel-header>
                                                            <div>
                                                                <v-row>
                                                                    <v-col class="colcenter">
                                                                        <img alt="Avatar" class="bancosimg"
                                                                            :src="`playground_assets/bancos/` + item.wcb_banco + `.png`" />
                                                                    </v-col>
                                                                    <v-col style="align-self: center;" class="colleft">
                                                                        <span class="bancotable"> {{
                                                                            item.wcb_banco_nombre }}</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </div>
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content style="text-align: left">
                                                            <template>
                                                                <div>
                                                                    
                                                                        <v-row v-for="(item2, index2) in item.cuentas" class="columnCuenta"
                                                                                    :key="index2">
                                                                                    <v-col cols="6" sm="6" md="6" lg="2" xl="2" class="colcenter">
                                                                                        <v-radio
                                                                                            :key="item2.wcb_numero"
                                                                                            :value="item2.wcb_numero"
                                                                                            color="azulsitio"
                                                                                            @click="selectBancoCuenta(item2.wcb_cuenta)">
                                                                                        </v-radio>
                                                                                    </v-col>
                                                                                    <v-col cols="6" sm="6" md="6" lg="3" xl="3" class="colcenter">
                                                                                        <span
                                                                                                            class="tipocuentatable">{{
                                                                                                                item2.wcb_tipo_cuenta_nombre
                                                                                                            }} </span>
                                                                                    </v-col>
                                                                                    <v-col cols="12" sm="12" md="10" lg="3" xl="3" class="colcenter">
                                                                                        <span
                                                                                                            class="numerocuentatable">{{
                                                                                                                item2.wcb_cuenta
                                                                                                            }}</span>
                                                                                    </v-col>
                                                                                    <v-col cols="6" sm="6" md="6" lg="2" xl="2" class="colcenter">
                                                                                        <div
                                                                                                            class="actioncuentatable">
                                                                                                            <v-icon
                                                                                                                class="ml-1 selectCuenta"
                                                                                                                @click="cambiaCuenta(item2)">
                                                                                                                mdi-pencil
                                                                                                            </v-icon>

                                                                                                        </div>
                                                                                    </v-col>
                                                                                    <v-col cols="6" sm="6" md="6" lg="2" xl="2" class="colcenter">
                                                                                        <div
                                                                                                            class="actioncuentatable">
                                                                                                            <v-icon
                                                                                                                class="ml-2 selectCuenta"
                                                                                                                @click="borrarCuenta(item2)">
                                                                                                                mdi-trash-can-outline
                                                                                                            </v-icon>

                                                                                                        </div>
                                                                                    </v-col>
                                                                                </v-row>
                                                                        <!-- <v-col cols="2" sm="2"></v-col> -->
                                                                        <!-- <v-col cols="10" sm="10">
                                                                            <v-simple-table>
                                                                                <template v-slot:default>
                                                                                    <tbody>
                                                                                        <tr v-for="(item2, index2) in item.cuentas"
                                                                                            :key="index2"
                                                                                            style="background-color: white !important">
                                                                                            <td>
                                                                                                <span
                                                                                                    class="tipocuentatable">{{
                                                                                                        item2.wcb_tipo_cuenta_nombre
                                                                                                    }} </span>

                                                                                            </td>
                                                                                            <td>
                                                                                                <span
                                                                                                    class="numerocuentatable">{{
                                                                                                        item2.wcb_cuenta
                                                                                                    }}</span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div
                                                                                                    class="actioncuentatable">
                                                                                                    <v-icon
                                                                                                        class="ml-1 selectCuenta"
                                                                                                        @click="cambiaCuenta(item2)">
                                                                                                        mdi-pencil
                                                                                                    </v-icon>

                                                                                                </div>

                                                                                            </td>
                                                                                            <td>
                                                                                                <div
                                                                                                    class="actioncuentatable">
                                                                                                    <v-icon
                                                                                                        class="ml-2 selectCuenta"
                                                                                                        @click="borrarCuenta(item2)">
                                                                                                        mdi-trash-can-outline
                                                                                                    </v-icon>

                                                                                                </div>

                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </template>
                                                                            </v-simple-table>
                                                                        </v-col> -->
                                                                    
                                                                </div>
                                                            </template>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>

                </v-col>
                <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter" v-if="estadoWallet == 5">
                    <v-row class="colcenter cuadroretiro">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-60-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="retirar-dinero">Registrar Cuenta Bancaria</div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="nrocli">
                                        Revise sus datos e ingrese la información solicitadas en los campos para crear
                                        una nueva cuenta
                                        bancaria.
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-61-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <span class="control-span">Mis datos personales</span>
                                    <span class="saldo">Nombres: {{ nombres }}</span>
                                    <span class="saldo">Apellidos: {{ apellidos }}</span>
                                    <span class="saldo">RUT: {{ formateaRut(rut) }}</span>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <span class="control-span">Nueva Cuenta Bancaria</span>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <v-form ref="form" v-model="validCuenta" style="width: 100%;">
                                        <v-row class="colcenter">
                                            <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="colcenter">
                                                <v-select v-model="bancoSeleccionado" :items="bancos.nombre"
                                                    placeholder="Selecciona un banco" v-on:change="selectBanco" filled
                                                    label="Banco">
                                                    <template v-slot:append>
                                                        <div v-if="validbanco == `no`"></div>
                                                        <v-icon v-else-if="!validbanco" color="m100"
                                                            style="margin-bottom: 10px !important ">
                                                            mdi-checkbox-marked-circle
                                                        </v-icon>
                                                        <v-icon v-else-if="validbanco" color="red"
                                                            style="margin-bottom: 10px !important ">
                                                            mdi-close-circle
                                                        </v-icon>
                                                    </template>
                                                </v-select>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="colcenter">
                                                <v-select v-model="tipoCuentaSeleccionada" :items="tipoCuentas.nombre"
                                                    placeholder="Selecciona tipo cuenta" v-on:change="selectTipoCuenta"
                                                    filled label="Tipo cuenta">
                                                    <template v-slot:append>
                                                        <div v-if="validtipocuenta == `no`"></div>
                                                        <v-icon v-else-if="!validtipocuenta" color="m100"
                                                            style="margin-bottom: 10px !important ">
                                                            mdi-checkbox-marked-circle
                                                        </v-icon>
                                                        <v-icon v-else-if="validtipocuenta" color="red"
                                                            style="margin-bottom: 10px !important ">
                                                            mdi-close-circle
                                                        </v-icon>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="colcenter">
                                                <v-text-field v-model="formNuevaCuenta.nroCuenta" ref="nrocuenta"
                                                    :rules="nroCuentaRules" placeholder="Ejemplo 123456789" required
                                                    filled label="Número de cuenta" min="3" max="99999999999999999999"
                                                    maxlength="20" append-icon="mdi-check-circle" color="rgb(56,0,151)"
                                                    type="number" class="w100" @update:error="vnrocuenta">
                                                    <template v-slot:append>
                                                        <div v-if="validnrocuenta == `no`"></div>
                                                        <v-icon v-else-if="!validnrocuenta" color="m100"
                                                            style="margin-bottom: 10px !important">
                                                            mdi-checkbox-marked-circle
                                                        </v-icon>
                                                        <v-icon v-else-if="validnrocuenta" color="red"
                                                            style="margin-bottom: 10px !important">
                                                            mdi-close-circle
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="colcenter">
                                                <v-text-field v-model="formNuevaCuenta.correo" :rules="emailRules"
                                                    placeholder="Escriba el correo electrónico" required filled
                                                    label="Correo Electrónico" append-icon="mdi-check-circle"
                                                    class="w100" color="rgb(56,0,151)" @update:error="vemail"
                                                    @keypress="vemailp()">
                                                    <template v-slot:append>
                                                        <div v-if="validemail == `no`"></div>
                                                        <v-icon color="m100" v-else-if="!validemail"
                                                            style="margin-bottom: 10px !important ">
                                                            mdi-checkbox-marked-circle
                                                        </v-icon>
                                                        <v-icon color="red" v-else="validemail"
                                                            style="margin-bottom: 10px !important ">
                                                            mdi-close-circle
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="5" lg="5" xl="5" v-if="!cuentaSeleccionada"
                                                class="colcenter">
                                                <v-btn x-large rounded color="juanito" large @click="validateCuenta"
                                                    :disabled="!validCuenta">
                                                    <span style="color: white">Crear cuenta</span>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="5" lg="5" xl="5" v-if="cuentaSeleccionada"
                                                class="colcenter">
                                                <v-btn x-large rounded color="juanito" large @click="validateCuenta2"
                                                    :disabled="!validCuenta">
                                                    <span style="color: white">Actualizar cuenta</span>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-form>

                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>

                </v-col>
                <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter" v-if="estadoWallet == 6">
                    <v-row class="colcenter cuadroretiro">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-60-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="retirar-dinero">Registrar Cuenta Bancaria</div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-61-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <v-row class="colcenter">
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                            <v-icon large color="success"
                                                style="margin-bottom: 10px !important; font-size: 70px">
                                                mdi-check-circle
                                            </v-icon>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                            <span class="control-span">¡Felicidades!</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                            <span class="saldo">El retiro se realizo con éxito</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                                    <v-btn rounded color="juanito" class="sm-size" x-large @click="cambiaMenuWallet(7)">
                                        <span style="color: white">Realizar otro retiro</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                                    <v-btn rounded outlined color="#04137f" class="sm-size" x-large
                                        @click="cambiaMenuWallet(4)">
                                        <span>Listado de cuentas</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="10" xl="10" class="colcenter" v-if="estadoWallet == 9">
                    <v-row class="colcenter">
                        <v-col cols="12" sm="12" md="12" lg="11" xl="11" class="colleft">
                            <div class="ofertas2">OFERTAS</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="10" xl="10" class="colcenter" v-if="estadoWallet == 9">
                    <v-row class="colcenter cuadroretiro">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-60-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="clienten">Descubre Nuestra Billetera Electrónica</div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                                    <div class="nrocli">
                                        Tu solución segura y conveniente para gestionar tus finanzas
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter frame-61-2">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <v-row class="colcenter">
                                        <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="colcenter frame-53">
                                            <v-row class="colcenter">
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                    <span class="control-span">
                                                        Control Total
                                                        </br>
                                                        de tus Finanzas
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                    <span class="control-span2">
                                                        Tus fondos protegidos con la más alta seguridad.

                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                    <img class="dia"
                                                        src="assets/privado/wallet/oferta/dia3.svg" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="colcenter frame-53">
                                            <v-row class="colcenter">
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                    <span class="control-span">
                                                        Promociones
                                                        </br>
                                                        Exclusivas
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                    <span class="control-span2">
                                                        Accede a ofertas y descuentos especiales para clientes.
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                    <img class="dia"
                                                        src="assets/privado/wallet/oferta/group-610.svg" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="colcenter frame-53">
                                            <v-row class="colcenter">
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                    <span class="control-span">
                                                        Paga rápido
                                                        </br>
                                                        y fácil
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                    <span class="control-span2">
                                                        Realiza pagos y transferencias de manera instantánea.
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                    <img class="dia"
                                                        src="assets/privado/wallet/oferta/dia6.svg" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <div class="component-52">
                                        <div class="submit2">CONTACTO</div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>

                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter" v-if="estadoWallet">
                    <v-row class="colcenter">
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                            <v-btn class="sm-size" x-large rounded color="#04137f" @click="volver"><span><span
                                        style="color:white">VOLVER</span></span></v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="11" sm="11" md="11" lg="11" xl="11" class="colcenter"></v-col>
                <v-col cols="11" sm="11" md="11" lg="11" xl="11" class="colcenter"></v-col>
                <v-col cols="11" sm="11" md="11" lg="11" xl="11" class="colcenter">
                    <div
                        class="para-retirar-dinero-debes-tener-al-menos-una-cuenta-personal-registrada-para-registrar-una-nueva-cuenta-ingresa-a-agregar-cuenta-para-retirar-dinero-presiona-retirar-el-retiro-ser-abonado-en-la-cuenta-en-un-plazo-de-72-hrs-h-biles">
                        Para retirar dinero debes tener al menos una cuenta personal registrada.
                        <br>
                        Para registrar una nueva Cuenta ingresa a Agregar Cuenta +
                        <br>
                        Para retirar dinero presiona Retirar.
                        <br>
                        El retiro será abonado en la cuenta en un plazo de 72 hrs hábiles
                    </div>
                </v-col>
                <v-col cols="11" sm="11" md="11" lg="11" xl="11" class="colcenter"></v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <v-dialog v-model="dialogRetiro" class="modalSize" max-width="100%" display="flex" justify-center="center">
            <template>
                <div class="modalDialog">
                    <div class="dialogStyle colcenter">
                        <v-row class="colcenter marcoDialog">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <div class="mi-simulaci-n">
                                    <img class="vectordia" src="assets/privado/modalsimulacion/vector0.svg">
                                    AVISO APLICACIÓN DE RETIRO
                                </div>

                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <div class="">
                                    <v-form ref="form" v-model="valid">

                                        <v-row dense class="colcenter">
                                            <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                                                <div class="devoluci-n-aproximada-de-prima-no-consumida">
                                                    Su solicitud de retiro ha sido ingresada, un  ejecutivo lo contactara.
                                                </div>
                                            </v-col>


                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>

                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                <div class="line-1"></div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                                            
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                <div class="frame-32-2">
                                                    <v-row class="colcenter">
                                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                            <v-btn  rounded x-large @click="salirModal"
                                                                color="#1d2eff" ><span><span
                                                                        style="color:white">CONTINUAR</span></span></v-btn>
                                                        </v-col>
                                                    </v-row>

                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-form>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-dialog>
        </v-col>
        <Movimiento :Movimiento="Movimiento" :dialogmovimiento="dialogmovimiento"></Movimiento>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-row>
</template>
<script>
import datausuario from "../../../../services/usuario-datos";
import privado from "../../../../services/privado";
import listadoRegion from "../../../../services/region";
import parametros from "../../../../services/parametros";
import Movimiento from "./components/Movimiento.vue";

export default {
    name: "MiWallet",
    components: {
        Movimiento: Movimiento
    },
    data() {
        return {
            dialogmovimiento: false,
            dialogRetiro: false,
            movimiento: false,
            Movimiento: false,
            movcartola: false,
            cuentaSelect: false,
            dialog: false,
            cuentaSeleccionada: false,
            bancoSeleccionado: null,
            tipoCuentaSeleccionada: null,
            codigovalido: true,
            codigo: "",
            valid: false,
            validRetiro: false,
            validCuenta: false,
            selectCuenta: "",
            validSelectCuenta: false,
            validCodigo: false,
            datosRetiro: {
                "wde_contrato": 0,
                "wde_cuenta_bancaria": 0,
                "wde_monto": 0
            },
            prefix: "$",
            validgiro: true,
            validnrocuenta: "no",
            validemail: "no",
            validbanco: "no",
            validtipocuenta: "no",
            giro: "",
            cuentasBancarias: [],
            retiroAplicado: {
                "wde_contrato": 0,
                "wde_numero": 0,
                "wde_codigo_autorizacion": 0
            },
            codigoRules: [
                (v) => (v && v.length == 4) || "4 caracteres",
            ],
            cuentaRules: [(v) => !!v || "seleccione una cuenta"],
            giroPermitidoRules: [
                (v) => !!v || "el campo saldo credito es requerido",
                (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
                (v) => (v && this.saldoWalletv(v)) || "no puede superar el saldo",
                (v) => (v && v != "0") || "el saldo debe ser mayor a 0",
                // (v) =>
                //   (v && this.validarMaxGiro(v)) ||
                //   "monto máximo para este credito es de $ " +
                //     this.$root.$refs.CotizaForm.restricciones[0].tmn_valor_credito_maximo
                //       .toFixed(0)
                //       .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."),
                // (v) =>
                //   (v && this.validarMinGiro(v)) ||
                //   "monto mínimo para este credito es de $ " +
                //     this.$root.$refs.CotizaForm.restricciones[0].tmn_valor_credito_minimo
                //       .toFixed(0)
                //       .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."),
            ],
            emailRules: [
                (v) => !!v || "el campo Correo es requerido",
                (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
                (v) => /.+@.+\..+/.test(v) || "Ingrese un correo válido",
                (v) => this.validaMail(v) || "largo mínimo de dominio 3 caracteres",
            ],
            nroCuentaRules: [
                (v) => !!v || "número de cuenta es requerido",
                (v) => (v && v.length <= 20) || "largo máximo 20 números",
                (v) => (v && v.length >= 3) || "largo mínimo 3 números",
                // (v) => (v && this.validarMaxTotal(v)) || "wde_tipo_mov máximo para este campo es de "+this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_original_maximo.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "1.")+" meses",
                // (v) => (v && this.validarMinTotal(v)) || "valor mínimo para este campo es de "+this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_original_minimo.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "1.")+" meses",
            ],
            formNuevaCuenta: {
                banco: "",
                tipoCuenta: "",
                nroCuenta: "",
                correo: "",
            },
            preguntas: [],
            dialog1: false,
            dialog2: false,
            overlay: false,
            estadoWallet: this.$store.state.seleccionMenuWallet,
            fechadesde: "",
            fechahasta: "",
            menu: false,
            menu2: false,
            dateRules: [
                (v) => (v && v.length == 10) || "largo fecha 10 caracteres",
                (v) => this.existeFecha(v) || "ingrese una fecha real",
            ],
            cliente: this.$store.state.clienteWallet,
            headers: [
                {
                    text: "Fecha",
                    align: "start",
                    sortable: false,
                    value: "fecha",
                    color: "#7130ED",
                },
                { text: "N° Operación", value: "operacion" },
                { text: "Origen", value: "origen" },
                { text: "Monto", value: "monto" },
                { text: "Acción", value: "accion" },
            ],
            registros: [],
            headerscartola: [
                {
                    text: "Fecha",
                    align: "start",
                    sortable: false,
                    value: "fecha",
                    color: "#7130ED",
                },
                { text: "Cuenta", value: "cuenta" },
                { text: "N° Operación", value: "operacion" },
                { text: "Origen", value: "origen" },
                { text: "Monto", value: "monto" },
                { text: "Acción", value: "accion" },
            ],
            datoscartola: [],
            datoscartolamem: [],
            cuentas: ["Todas", "Cuenta N° 1", "Cuenta N° 2", "Cuenta N° 3"],
            movimientos: ["Todas", "Abonos", "Retiros", "Entre Banco"],
            bancos: this.$store.state.bancos,
            tipoCuentas: this.$store.state.tipoCuentas,
            activePicker: null,
            filtro1: "",
            filtro2: "",
            filtro3: "",
            filtro4: "",
            nombres:
                this.$root.$refs.HomePrivado.loginUsuario.acc_nombre_principal +
                " " +
                this.$root.$refs.HomePrivado.loginUsuario.acc_nombre_segundo,
            apellidos:
                this.$root.$refs.HomePrivado.loginUsuario.acc_ape_materno +
                " " +
                this.$root.$refs.HomePrivado.loginUsuario.acc_ape_paterno,
            rut:
                this.$root.$refs.HomePrivado.loginUsuario.acc_rut +
                "-" +
                this.$root.$refs.HomePrivado.loginUsuario.acc_dv,
        };
    },

    methods: {
        salirModal(){
            this.dialogRetiro=false;
        },
        volver() {
            this.estadoWallet = 0;
        },
        cargaModalMovimientos(item) {
            this.dialogmovimiento = true;
            this.Movimiento = item;
        },
        cargaModalCartola(item) {
            this.dialogmovimiento = true;
            this.Movimiento = item;
        },
        borrarCuenta(seleccion) {
            this.cuentaSelect = seleccion;
            this.dialog = true;
        },
        async eliminaCuenta() {
            this.overlay = true;
            let cuenta = {
                wcb_numero: this.cuentaSelect.wcb_numero,
            };
            this.dialog = false;
            var res = await privado.walletCuentaBancariaEliminar(cuenta);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                this.$alertas(res.data.estadoTransaccion.errors, "success");
                this.limpiarFormCuenta();
                this.cambiaMenuWallet(4);
            } else {
                this.limpiarFormCuenta();
                this.cambiaMenuWallet(4);
                this.$alertas(res.data.estadoTransaccion.errors, "info");
                this.overlay = false;
            }
            this.overlay = false;
        },
        cambiaCuenta(cuenta) {

            this.cuentaSeleccionada = false;
            this.cambiaMenuWallet(5, cuenta);
        },
        nuevocodigo() {
            this.codigovalido = true;
        },
        vcodigo(v) {
            this.validCodigo = v;
        },
        vcuenta(v) {
            this.validSelectCuenta = v;
        },
        selectBancoCuenta(cuenta) {
            this.datosRetiro.wde_cuenta_bancaria = parseInt(cuenta);
        },
        validateRetiro() {
            if (this.$refs.form2.validate()) {
                this.datosRetiro.wde_contrato = this.$root.$refs.HomePrivado.loginUsuario.contrato.ctt_numero;
                this.datosRetiro.wde_cuenta_bancaria = parseInt(this.selectCuenta);
                this.datosRetiro.wde_monto = parseInt(this.giro);

                this.guardarRetiro();
            }
        },
        async guardarRetiro() {
            this.overlay = true;
            var codRetiro = {};
            var res = await privado.walletRetiroGrabar(this.datosRetiro);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                this.$alertas(res.data.estadoTransaccion.errors, "success");
                this.retiroAplicado = {
                    "wde_contrato": res.data.dtoTransaccionRetiro.wde_contrato,
                    "wde_numero": res.data.dtoTransaccionRetiro.wde_numero,
                    "wde_codigo_autorizacion": 0
                },
                    // this.estadoWallet=8;
                    // this.aplicarRetiro();
                this.cambiaMenuWallet(0);
                this.overlay = false;
                this.dialogRetiro=true;
            } else {
                this.$alertas(res.data.estadoTransaccion.errors, "info");
                this.codigovalido = false;
                this.codigo = "";
                this.overlay = false
            }

        },
        async aplicarRetiro() {
            this.overlay = true;
            this.retiroAplicado.wde_codigo_autorizacion = 0;
            var res = await privado.walletRetiroAplicar(this.retiroAplicado);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                this.$alertas(res.data.estadoTransaccion.errors, "success");
                this.estadoWallet = 0;
                this.overlay = false;
            } else {
                this.$alertas(res.data.estadoTransaccion.errors, "info");
                this.codigovalido = false;
                this.codigo = "";
                this.overlay = false
            }

        },
        limpiarFormCuenta() {
            this.formNuevaCuenta = {
                banco: "",
                tipoCuenta: "",
                nroCuenta: "",
                correo: "",
            };
            this.validnrocuenta = "no";
            this.validemail = "no";
            this.validbanco = "no";
            this.validtipocuenta = "no";
        },
        validateCuenta() {
            if (this.$refs.form.validate()) {
                this.guardaCuenta();
            }
        },
        validateCuenta2() {
            if (this.$refs.form.validate()) {
                this.guardaCuenta();
            }
        },
        async guardaCuenta() {
            this.overlay = true;
            let cuenta = {
                wcb_contrato: this.$root.$refs.HomePrivado.loginUsuario.contrato.ctt_numero,
                wcb_banco: this.formNuevaCuenta.banco,
                wcb_tipo_cuenta: this.formNuevaCuenta.tipoCuenta,
                wcb_cuenta: this.formNuevaCuenta.nroCuenta,
                wcb_correo: this.formNuevaCuenta.correo,

            };

            if (this.cuentaSeleccionada) {
                cuenta.wcb_numero = this.cuentaSeleccionada;
            }
            var res = await privado.walletCuentaBancariaRegistrar(cuenta);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                this.$alertas(res.data.estadoTransaccion.errors, "success");
                this.limpiarFormCuenta();
                if (this.cuentaSeleccionada) {
                    this.estadoWallet = 4;
                } else {
                    this.estadoWallet = 6;
                }
                this.overlay = false;
            } else {

                this.$alertas(res.data.estadoTransaccion.errors, "info");
                this.overlay = false;
            }

        },
        selectBanco(e) {
            this.validbanco = false;
            //  this.$store.commit('setselecciongenero', e);
            let i = 0;
            let idx = 0;
            this.bancos.nombre.forEach((element) => {
                if (element == e) {
                    idx = i;
                }
                i++;
            });
            this.formNuevaCuenta.banco = this.bancos.codigo[idx];
        },
        selectTipoCuenta(e) {
            this.validtipocuenta = false;
            //  this.$store.commit('setselecciongenero', e);
            let i = 0;
            let idx = 0;
            this.tipoCuentas.nombre.forEach((element) => {
                if (element == e) {
                    idx = i;
                }
                i++;
            });
            this.formNuevaCuenta.tipoCuenta = this.tipoCuentas.codigo[idx];
        },
        formateaRut(rut) {
            if (!rut || rut == "") {
                return;
            }
            var actual = rut.replace(/^0+/, "");
            if (actual != "" && actual.length > 0) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 == 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            return (this.rut = rutPuntos);
        },
        selectFiltro1(e) {
            this.filtro1 = e;
            this.datoscartola = this.datoscartolamem;
            var datamem = [];
            var sw = 0;
            this.datoscartola.forEach((element) => {
                sw = 0;
                if (element.cuenta == e || this.filtro1 == "") {

                    datamem.push(element);
                    sw = 1;
                }
            });
            this.datoscartola = datamem;

        },
        selectFiltro2(e) {
            this.filtro2 = e;
            this.datoscartola = this.datoscartolamem;
            var datamem = [];
            this.datoscartola.forEach((element) => {
                if (element.cuenta == e || this.filtro2 == "") {
                    datamem.push(element);
                }
            });
            this.datoscartola == datamem;
        },
        async datosCuentas() {
            var res = await privado.walletBancosListar();
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                this.bancos = {
                    nombre: [],
                    codigo: [],
                };
                res.data.listaBancos.forEach((element) => {
                    this.bancos.nombre.push(element.int_nombre);
                    this.bancos.codigo.push(element.int_numero);
                });
            } else {
                this.$alertas(res.data.estadoTransaccion.errors, "info");
                this.overlay = false;
            }
            var res2 = await privado.walletTipoCuentasListar();
            if (res2.status == 200 && res.data.estadoTransaccion.success) {
                this.tipoCuentas = {
                    nombre: [],
                    codigo: [],
                };
                res2.data.listaCuentas.forEach((element) => {
                    this.tipoCuentas.nombre.push(element.tca_glosa);
                    this.tipoCuentas.codigo.push(element.tca_numero);
                });
            } else {
                this.$alertas(res2.data.estadoTransaccion.errors, "info");
                this.overlay = false;
            }
        },
        async cambiaMenuWallet(menu, cuenta = false) {
            this.$store.commit("setseleccionmenuwallet", menu);
            this.overlay = true;
            var res = false;
            var res2 = false;
            this.cliente.numero = this.$root.$refs.HomePrivado.loginUsuario.contrato.ctt_numero;
            // this.cliente.saldo="$ 0";
            if (menu == 2 || menu == 1 || menu == 0 || menu == 7) {
                var contrato = {
                    wcb_contrato: this.$root.$refs.HomePrivado.loginUsuario.contrato.ctt_numero,
                };

                res = await privado.walletTransaccionesListar(contrato);
                if (res.status == 200 && res.data.estadoTransaccion.success) {
                    this.registros = [];

                    this.cliente.saldo = res.data.dtoTransaccion.wal_saldo;
                    let monto = "";
                    res.data.listTransacciones.forEach((element) => {
                        monto = element.wde_monto;

                        this.registros.push({
                            fecha: element.wde_fecha,
                            operacion: element.wde_numero,
                            origen: element.wde_nombre_banco,
                            monto: monto,
                            accion: "",
                        });
                    });
                    this.overlay = false;
                } else {
                    this.registros = [];

                    this.$alertas(res.data.estadoTransaccion.errors, "info");
                    this.overlay = false;
                }
            }
            if (menu == 3) {
                var contrato = {
                    wcb_contrato: this.$root.$refs.HomePrivado.loginUsuario.contrato.ctt_numero,
                };

                res = await privado.walletTransaccionesListar(contrato);
                if (res.status == 200 && res.data.estadoTransaccion.success) {
                    this.datoscartola = [];

                    this.cliente.saldo = res.data.dtoTransaccion.wal_saldo;
                    let monto = "";
                    res.data.listTransacciones.forEach((element) => {
                        monto = element.wde_monto;

                        this.datoscartola.push({
                            fecha: element.wde_fecha,
                            cuenta: element.wde_numero,
                            operacion: element.wde_numero,
                            origen: element.wde_nombre_banco,
                            monto: monto,
                            tipo: element.wde_tipo_mov,
                            accion: "",
                        });
                    });
                    this.datoscartolamem = this.datoscartola;
                    this.overlay = false;
                } else {
                    this.datoscartola = [];
                    this.$alertas(res.data.estadoTransaccion.errors, "info");
                    this.overlay = false;
                    this.datoscartolamem = this.datoscartola;
                }
            }
            if (menu == 4) {
                var contrato = {
                    wcb_contrato: this.$root.$refs.HomePrivado.loginUsuario.contrato.ctt_numero,
                };
                res = await privado.walletCuentaBancariaListar(contrato);
                this.cuentasBancarias = [];
                if (res.status == 200 && res.data.estadoTransaccion.success) {
                    this.cuentasBancarias = res.data.bancos;
                    this.overlay = false;
                } else {
                    this.datoscartola = [];
                    this.$alertas(res.data.estadoTransaccion.errors, "info");
                    this.overlay = false;
                    this.datoscartolamem = this.datoscartola;
                }
            }
            if (menu == 7) {

                var contrato = {
                    wcb_contrato: this.$root.$refs.HomePrivado.loginUsuario.contrato.ctt_numero,
                };

                this.giro = "";
                this.cuentasBancarias = [];
                res = await privado.walletCuentaBancariaListar(contrato);
                if (res.status == 200 && res.data.estadoTransaccion.success) {
                    this.cuentasBancarias = res.data.bancos;
                    this.overlay = false;
                } else {
                    this.datoscartola = [];
                    this.$alertas(res.data.estadoTransaccion.errors, "info");
                    this.overlay = false;
                }
            }
            if (menu == 9) {
                res = await privado.walletPreguntasListar();
                if (res.status == 200 && res.data.estadoTransaccion.success) {
                    this.preguntas = res.data.preguntasWallets;
                    this.overlay = false;
                } else {
                    this.datoscartola = [];
                    this.$alertas(res.data.estadoTransaccion.errors, "info");
                    this.overlay = false;
                }
            }

            if (menu == 5 && cuenta) {

                let datos_banco = { "nombre": cuenta.wcb_banco, "codigo": cuenta.wcb_banco_nombre };

                this.cuentaSeleccionada = cuenta.wcb_numero;
                this.bancoSeleccionado = cuenta.wcb_nombre_banco;
                this.tipoCuentaSeleccionada = cuenta.wcb_tipo_cuenta_nombre;
                this.formNuevaCuenta.nroCuenta = cuenta.wcb_cuenta;
                this.formNuevaCuenta.correo = cuenta.wcb_correo;
                this.formNuevaCuenta.banco = cuenta.wcb_banco;
                this.formNuevaCuenta.tipoCuenta = cuenta.wcb_tipo_cuenta;
                this.overlay = false;

            } else {
                this.cuentaSeleccionada = false;
                this.bancoSeleccionado = null;
                this.tipoCuentaSeleccionada = null;
                this.formNuevaCuenta.nroCuenta = null;
                this.formNuevaCuenta.correo = null;
                this.overlay = false;
            }

            this.estadoWallet = menu;

        },

        vgiro(v) {
            this.validgiro = v;
        },
        vnrocuenta(v) {
            this.validnrocuenta = v;
        },
        vemailp() {
            if (this.validemail == "no") {
                this.validemail = true;
            }
        },
        validaMail(email) {
            if (!email || email == "") {
                return;
            }
            var revmail = email.split("@");
            if (revmail.length == 2) {
                if (revmail[1].length >= 5) {
                    return true;
                }
                return false;
            }
        },
        vemail(v) {
            this.validemail = v;
        },
        vdate(v) {
            this.validdate = v;
        },
        vdate2(v) {
            this.validdate = v;
        },
        existeFecha(fecha) {
            if (fecha === undefined || fecha === null) {
                return;
            }
            var fechaf = fecha.split("/");
            var day = fechaf[0];
            var month = fechaf[1];
            var year = fechaf[2];
            var date = new Date(year, month, "0");
            if (year <= 1920 || day > 31 || month > 12 || day - 0 > date.getDate() - 0) {
                return false;
            }

            return true;
        },
        formatoFecha(fecha) {
            var v = fecha;
            v = v.replace(/\D/g, "").slice(0, 10);
            if (v.length >= 5) {
                v = v.slice(0, 2) + "/" + v.slice(2, 4) + "/" + v.slice(4);
            } else if (v.length >= 3) {
                v = v.slice(0, 2) + "/" + v.slice(2);
            }

            if (v.length == 10) {
                this.calendario();
            }
            this.fechadesde = v;
        },
        formatoFecha2(fecha) {
            var v = fecha;
            v = v.replace(/\D/g, "").slice(0, 10);
            if (v.length >= 5) {
                v = v.slice(0, 2) + "/" + v.slice(2, 4) + "/" + v.slice(4);
            } else if (v.length >= 3) {
                v = v.slice(0, 2) + "/" + v.slice(2);
            }

            if (v.length == 10) {
                this.calendario();
            }
            this.fechahasta = v;
        },
        cancelaDialog(item) {

            this.dialog1 = false;
        },
        save(date) {
            // date=this.formatDate(date);
            this.$refs.menu.save(date);
        },
        save2(date) {
            // date=this.formatDate(date);
            this.$refs.menu2.save(date);
        },
        formatDate: function (date) {
            if (!date) return null;

            var arr = date.split("-"),
                year = arr[0],
                month = arr[1],
                day = arr[2];
            return `${day}/${month}/${year}`;
        },
        parseDate: function (date) {
            this.dateCompara = date;
            if (!date) return null;

            var arr = date.split("/"),
                day = arr[0],
                month = arr[1],
                year = arr[2];
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        },
        saldoWalletv(v) {
            v = v.replace(/[^0-9]/g, "");
            let saldo = this.cliente.saldo.replace(/[^0-9]/g, "");
            if (parseInt(v) > parseInt(saldo)) {
                return false;
            }
            return true;
        }
    },
    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date);
        },
        saldoWallet() {
            return this.cliente.saldo.replace("$", "");
        },

    },
    watch: {
        date(val) {
            this.fechadesde = this.formatDate(this.date);
        },
        date2(val) {
            this.fechahasta = this.formatDate(this.date2);
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
        menu2(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
    },
    mounted() {
        this.datosCuentas();
        this.cambiaMenuWallet(0);
        this.formNuevaCuenta = {
            banco: "",
            tipoCuenta: "",
            nroCuenta: "",
            correo: "",
        };
    },
    created() {
        this.$root.$refs.WalletMo = this;
    },
    metaInfo: {
        title: "Mi Wallet",
    },
};
</script>
<style scoped>
.devoluci-n-aproximada-de-prima-no-consumida {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 400);
    font-size: clamp(0.8rem, 5vw, 1.7rem);
    line-height: clamp(1rem, 5vw, 1.7rem);
    position: relative;
}
.mi-simulaci-n {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
    /* font-size: var(--headings-h3-font-size, 40px);
    line-height: var(--headings-h3-line-height, 48px); */
    font-size: clamp(1rem, 5vw, 2.2rem);
    line-height: clamp(1.3rem, 5vw, 2.6rem);
    font-weight: var(--headings-h3-font-weight, 500);
    position: relative;
    width: 100%;
}
.vectordia {
    width: 33px;
    height: 33px;
    position: relative;
    overflow: visible;
}
.marcoDialog {
    background: var(--blanco, #f0f2f2);

}
.modalDialog {
    display: flex;
    justify-content: center;
}
.dialogStyle {
    /* background: var(--blanco, #f0f2f2); */
    background: var(--blanco, #f0f2f2);
    border-radius: 40px;
    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    width: 70%;
}

.columnCuenta{
    border: 1px solid darkgray;
    border-radius: 10px;
    margin-bottom: 5px;
}
.lines {
    display: table-cell;
}

.cuadroform {
    padding: 20px;
}

.sm-size {
    width: 60%;
}

.cuadrodatosiz {
    align-self: center;
}

.divide {
    display: inline-flex;
}

.simulaPago {
    background-color: white;
}

.colcenter {
    display: flex;
    justify-content: center;
}

.colright {
    display: flex;
    justify-content: right;
}

.colleft {
    display: flex;
    justify-content: left;
}


.frame-60 {
    background: var(--azul-complementario, #04137f);
    border-radius: 40px 40px 0px 0px;
    padding: 20px 79px 20px 79px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.frame-60-2 {
    background: var(--azul-complementario, #04137f);
    border-radius: 40px 40px 0px 0px;
    padding: 20px 40px 20px 40px;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.frame-61 {
    background: var(--blanco, #f0f2f2);
    border-radius: 0px 0px 40px 40px;
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px;
    padding: 20px 44px 20px 44px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.classmonto {
    font-size: clamp(1rem, 4vw, 2.2rem);
}

.frame-61-2 {
    background: var(--blanco, #f0f2f2);
    border-radius: 0px 0px 40px 40px;
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px;
    padding: 20px 44px 20px 44px;
    gap: 80px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
}


.para-retirar-dinero-debes-tener-al-menos-una-cuenta-personal-registrada-para-registrar-una-nueva-cuenta-ingresa-a-agregar-cuenta-para-retirar-dinero-presiona-retirar-el-retiro-ser-abonado-en-la-cuenta-en-un-plazo-de-72-hrs-h-biles {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3rem);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.numero-de-cliente {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3rem);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.nrocli {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3rem);
    font-weight: var(--headings-h3-font-weight, 500);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.saldo {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 2rem);
    font-weight: var(--headings-h4-font-weight, 400);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.monto {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
    /* font-size: var(--headings-h2-font-size, 48px);
    line-height: var(--headings-h2-line-height, 60px); */
    font-size: clamp(1.2rem, 4vw, 2rem);
    line-height: clamp(1.5rem, 4vw, 2.8rem);
    font-weight: var(--headings-h2-font-weight, 700);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.retirar-dinero {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
    /* font-size: var(--headings-h3-font-size, 40px);
    line-height: var(--headings-h3-line-height, 48px); */
    font-size: clamp(0.8rem, 4vw, 1.8rem);
    line-height: clamp(1rem, 4vw, 1.6rem);
    font-weight: var(--headings-h3-font-weight, 500);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.clienten {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.8rem);
    line-height: clamp(0.8rem, 4vw, 1.5rem);
    font-weight: var(--headings-h4-font-weight, 400);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ofertas2 {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.8rem);
    line-height: clamp(1rem, 4vw, 1.6rem);
    font-weight: var(--headings-h2-font-weight, 700);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.frame-53 {
    background: var(--azul-claro, #e8f3ff);
    border-radius: 20px;
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    margin: 20px;
}

.control-total-de-tus-finanzas-tus-fondos-protegidos-con-la-m-s-alta-seguridad {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    position: relative;
    width: 100%;
}

.control-span {
    text-align: center;
    color: var(--azul-complementario, #04137f);
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.8rem);
    line-height: clamp(1rem, 4vw, 1.8rem);
    font-weight: var(--headings-h4-font-weight, 400);
}

.control-span2 {
    text-align: center;
    color: var(--azul-complementario, #04137f);
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.2rem);
    line-height: clamp(0.8rem, 4vw, 1.3rem);
    font-weight: var(--headings-h5-font-weight, 400);
}

.control-span3 {
    text-align: center;
    color: var(--azul-complementario, #04137f);
    font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.6rem, 4vw, 1rem);
    line-height: clamp(0.6rem, 4vw, 1rem);
    font-weight: var(--headings-h6-font-weight, 400);
}

.titdatosnuevacuenta {
    display: block;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    color: var(--colores-neutrales-dark, #272a31);
    font-family: Roboto;
    font-size: clamp(0.8rem, 4vw, 1.5rem);
    line-height: clamp(0.8rem, 4vw, 1.5rem);
    font-style: normal;
    font-weight: 400;
    margin-bottom: 15px;
}

.datosnuevacuenta {
    display: block;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    color: var(--colores-neutrales-dark, #272a31);
    font-family: Roboto;
    font-size: clamp(0.6rem, 4vw, 0.8rem);
    font-size: clamp(0.8rem, 4vw, 1rem);
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
}

.submit2 {
    color: var(--blanco, #f0f2f2);
    text-align: center;
    font-family: "CircularPro-Black", sans-serif;
    font-size: clamp(0.8rem, 4vw, 1.5rem);
    line-height: clamp(0.8rem, 4vw, 1.5rem);
    font-weight: 900;
    position: relative;
}

.component-52 {
    background: var(--degradado, linear-gradient(35deg, rgba(29, 46, 255, 1) 0%, rgba(83, 180, 220, 1) 52.99999713897705%, rgba(0, 229, 255, 1) 100%));
    border-radius: 30px;
    padding: 12px 20px 12px 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
}

.bancosimg {
    width: 140px;
    display: inline;
    align-self: center;
    /* margin-top: 10px; */
    border: 1px solid darkgray;
    border-radius: 10px;

}
.tipocuentatable {
  display: block;
  height: 17px;
  align-self: stretch;
  color: var(--colores-neutrales-80, #535967);
  font-family: Roboto;
  font-size: clamp(0.5rem, 4vw, 1rem);
  line-height: clamp(0.5rem, 4vw, 1rem);
  font-style: normal;
  font-weight: 700;
  /* 17.1px */
  margin-bottom: 5px;
}

.dia {
    flex-shrink: 0;
    width: clamp(2.5rem, 4vw, 3.5rem);
    position: relative;
    overflow: visible;
}

.btnderup {
    align-self: end;
}

.btnderdown {
    align-self: baseline;
}

.cuadroderbtn {
    padding: 20px;
}

.cuadroretiro {
    padding: 20px;
}

@media only screen and (max-width: 800px) {
    .frame-61-2 {
        padding: 0px !important;
    }

    .sm-size {
        font-size: small !important;
        width: 100%;
    }
}

@media only screen and (max-width: 1265px) {
    .lines {
        display: none !important;
    }
}
</style>