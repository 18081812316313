<template>
    <v-row class="colcenter ">

        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="cuadros">
            <v-row>
                <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter">
                    <img class="vector4" src="/assets/privado/firma/vector3.svg">
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter">
                    <div
                        class="para-gestionar-la-portabilidad-de-su-seguro-necesitamos-que-nos-firme-un-mandato-para-solicitar-la-renuncia-la-devoluci-n-de-la-prima-no-consumida-y-contratar-su-nuevo-seguro">
                        Para gestionar la portabilidad de su seguro, necesitamos que nos firme
                        un mandato para solicitar la renuncia, la devolución de la prima no
                        consumida y contratar su nuevo seguro.
                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <div
                        class="el-mandato-se-firma-electr-nicamente-por-lo-cual-ser-direccionado-un-sitio-seguro-para-verificaci-n-de-identidad-y-firma">
                        El mandato se firma electrónicamente por lo cual será direccionado un
                        sitio seguro para verificación de identidad y firma
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="10" sm="10" md="10" lg="10" xl="10" class="colcenter">
            <v-row class="colcenter">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                    <v-btn style="color: white !important;" v-if="!tipoFirma && !fmandato" rounded large block
                        @click="cambiaFirma(1)" :disabled="estadoFea==2" color="#380087">
                        <v-icon left style="color: white !important;"> mdi-swap-horizontal </v-icon>
                        Cambiar a Firma Manual
                    </v-btn>
                    <v-btn style="color: white !important;" v-if="tipoFirma && !fmandato" color="#380087" rounded large
                        block @click="cambiaFirma(2)" :disabled="estadoFea==1">
                        <v-icon left style="color: white !important;"> mdi-swap-horizontal </v-icon>
                        Cambia a Firma Electrónica
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="10" sm="10" md="10" lg="10" xl="10" class="colcenter">
            <span class="cambia-firma" v-if="!tipoFirma">Firma Electrónica</span>
            <span class="cambia-firma" v-if="tipoFirma">Firma Manual</span>
        </v-col>
        <v-col cols="10" sm="10" md="10" lg="10" xl="10" class="colcenter">
            <v-row class="colcenter">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                    <v-file-input v-if="tipoFirma" v-model="chosenMandato" ref="archivo1" prepend-icon="mdi-upload"
                        label="Seleccionar Mandato" accept="application/pdf, .gif,.jpeg" @change="cambia1()"
                        :disabled="fmandato" dense></v-file-input>

                    <!-- <v-btn v-if="!fmandato && !tipoFirma" style="color: rgb(255, 255, 255);" color="juanito" rounded x-large block 
                    @click="seleccionaCertificado(5)" :disabled="fmandato">
                        <v-icon v-if="fmandato && !tipoFirma" left color="success">
                            mdi-check-circle
                        </v-icon>
                        <v-icon left style="color: white !important;">
                            mdi-file-sign
                        </v-icon>
                        Firmar
                    </v-btn> -->
                </v-col>

                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                    <v-btn v-if="tipoFirma" style="color: white;" color="#1d2eff" rounded large @click="guardarDocumento(5)">
                        <v-icon v-if="fmandato && tipoFirma" left color="success mr-2" size="30px" style="margin-right: 20px;">
                            mdi-check-circle
                        </v-icon>
                        <v-icon left style="color: white !important;">
                            mdi-file-sign
                        </v-icon>
                        Firmar
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="10" sm="10" md="10" lg="10" xl="10" class="colcenter">
            <v-row class="colcenter">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                    <v-btn  v-if="!tipoFirma" class="sm-size descarga" rounded large @click="verMandato(1)" color="#04137f">
                        <v-icon left style="color: white !important;">
                            mdi-file-eye
                        </v-icon>
                        <span><span style="color:white">REVISA TU MANDATO AQUÍ</span></span></v-btn>
                    <v-btn  v-if="!tipoFirma" class="sm-size descarga2" rounded large @click="verMandatoMobile()" color="#04137f">
                        <v-icon left style="color: white !important;">
                            mdi-file-eye
                        </v-icon>
                        <span><span style="color:white">REVISA TU MANDATO AQUÍ</span></span></v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                    <!-- <v-btn v-if="tipoFirma" class="sm-size" rounded large @click="seleccionaCertificado(5)"
                        color="#1d2eff">
                        <v-icon v-if="fmandato && tipoFirma" left color="success">
                            mdi-check-circle
                        </v-icon>
                        <v-icon left style="color: white !important;">
                            mdi-file-sign
                        </v-icon>
                        <span><span style="color:white">FIRMA AQUÍ</span></span></v-btn> -->

                    <v-btn v-if="!tipoFirma" class="sm-size" rounded large @click="seleccionaCertificado(5)"
                        color="#1d2eff">
                        <v-icon v-if="fmandato && tipoFirma" left color="success mr-2" style="margin-right: 20px;">
                            mdi-check-circle
                        </v-icon>
                        <v-icon left style="color: white !important;">
                            mdi-file-sign
                        </v-icon>
                        <span><span style="color:white">FIRMA AQUÍ</span></span></v-btn>
                </v-col>
            </v-row>

        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <div class="line-1"></div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <div class="frame-32-2">
                <v-row class="colcenter">
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                        <v-btn class="sm-size" outlined rounded x-large
                            @click="volver"><span><span>REGRESAR</span></span></v-btn>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                        <v-btn class="sm-size" :disabled="!firma" rounded x-large @click="continuar" color="#1d2eff"><span><span
                              style="color:white">CONTINUAR</span></span></v-btn>
                    </v-col>
                </v-row>

            </div>
        </v-col>
        <v-dialog v-model="dialog" max-width="70%" style="border-radius: 20px">
            <template>
                <v-card>
                    <v-card-title>
                        <v-row style="width: 100%">
                            <v-col cols="1" sm="1"></v-col>
                            <v-col cols="10" sm="10">
                                <h1 style="width: 100%; display: block; text-align: center">
                                    Mandato
                                </h1>
                            </v-col>
                            <v-col cols="1" sm="1"><v-icon @click="dialog = false" style="
                            color: #380087;
                            cursor: hand;
                            margin-bottom: 5px !important ;
                            font-size: 40px;
                          ">
                                    mdi-close-circle-outline
                                </v-icon></v-col>
                            <v-col cols="12" sm="12">
                                <h3 style="width: 100%; display: block; text-align: center">

                                </h3>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-card-text>
                        <object v-if="extension == 'pdf'" width="100%" height="400px"
                            :data="`data:application/` + extension + `;base64,` + mandato"
                            type="application/pdf"></object>
                        <div v-if="extension == 'jpg' || extension == 'png' || extension == 'mpeg'"
                            class="d-flex flex-column justify-space-between align-center">
                            <v-slider v-model="width" class="align-self-stretch" min="200" max="1200"
                                step="1"></v-slider>

                            <v-img :aspect-ratio="16 / 9" :width="width"
                                :src="`data:image/` + extension + `;base64,` + mandato"></v-img>
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-row style="width: 100%" class="colcenter">

                            <v-col cols="10" sm="10" class="colcenter">
                                <div style="
                            border-radius: 30px;
                            padding: 7px;
                            padding-top: 8px;
                            padding-bottom: 8px;
                            background-color: #d1bbfd;
                          ">
                                    <h3 style="width: 100%; display: block; text-align: center">
                                        <v-icon style="margin-bottom: 5px !important ; font-size: xx-large">
                                            mdi-alert-circle-outline
                                        </v-icon>
                                        La firma electrónica se autentifica con clave única, por lo
                                        que será redireccionado.
                                    </h3>
                                </div>
                            </v-col>

                            <v-col cols="12" sm="12"></v-col>
                        </v-row>
                        <v-overlay :value="overlay2">
                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                        </v-overlay>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-row>

</template>
<script>
import datausuario from "../../../../../services/usuario-datos";
import fileservice from "../../../../../services/fileservice.js";
import firmaservice from "../../../../../services/firma.js";
export default {
    name: "DevolucionSeguro",
    components: {},
    data() {
        return {
            width: 800,
            tipoFirma: false,
            overlay: false,
            overlay2: false,
            overlay3: false,
            documentos: this.$store.state.documentos,
            seguro: this.$store.state.seguroSeleccion,
            mandato: this.$store.state.mandato,
            renuncia: this.$store.state.renuncia,
            fmandato: false,
            frenuncia: false,
            dialog: false,
            dialog2: false,
            dialog3: false,
            dialog4: false,
            dialog5: false,
            dialogFirma: false,
            chosenFile1: false,
            chosenFile2: false,
            chosenMandato: null,
            chosenRenuncia: null,
            cargadom1: false,
            cargador2: false,
            extension: "pdf",
            numero_mandato: 0,
            numero_renuncia: 0,
            doc_firma: 0,
            activaRenuncia: false,
            todo: false,
            estadoFea: this.$store.state.estadoFea,
            firma : this.$store.state.firma
        };
    },

    methods: {
        seleccionaCertificado(tipo) {
            this.doc_firma = 5;
            this.preparaFirma(false);

            // this.doc_firma = tipo;
            // console.log(tipo);
            // this.dialogFirma = true;
            // this.dialog=false;
            // this.todo=false;
        },
        seleccionaCertificadoTodo() {
            this.doc_firma = 5;
            this.preparaFirma(false);
            // this.dialogFirma = true;
            // this.dialog=false;
            // this.todo=true;
        },

        async guardarDocumento(doc) {
            this.overlay = true;
            var res = false;
            var filetmp = false;
            console.log("entraaaaaa");
            console.log(this.chosenMandato);
            let pasa = false;
            let pasaf = false;

            if (this.cargadom1 && doc == 5) {
                if (this.fmandato) {
                    pasaf = true;
                }
                filetmp = this.chosenMandato;
                pasa = true;
            }
            if (this.cargador2 && doc == 6) {
                if (this.frenuncia) {
                    pasaf = true;
                }
                filetmp = this.chosenRenuncia;
                pasa = true;
            }
            if (!pasa) {
                this.$alertas("Ingrese un documento", "warning");
                this.overlay = false;
                return;
            }
            if (pasaf) {
                this.$alertas("documento firmado", "warning");
                this.overlay = false;
                return;
            }
            var documento = {
                file: filetmp,
                seg_numero: this.seguro,
                cdo_tipo_docto: doc,
            };
            res = await fileservice.GuardaFirmado(documento);
            if (res.status == 200) {
                this.$alertas(res.data.estadoTransaccion.errors, "success");
                this.guardado = true;
                if (doc == 5) {
                    this.cargado1 = true;
                    this.fmandato = true;
                    this.dialog3 = false;
                    console.log(this.fmandato);
                }
                if (doc == 6) {
                    this.cargado2 = true;
                    this.frenuncia = true;
                    this.dialog4 = false;
                }
                this.firma = true;
                // if (this.fmandato && this.frenuncia) {

                // }
            } else {
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
                this.guardado = false;
            }
            this.overlay = false;
        },
        cambia1() {
            this.cargadom1 = true;
        },
        cambia2() {
            this.cargador2 = true;
        },
        cambiaFirma(opt) {
            // 1=>firma manual 2=> firma electrónica 3=>ambos
            if (opt == 1) {
                this.tipoFirma = true;
            } else {
                this.tipoFirma = false;
            }
        },
        volver() {
            if (this.$store.state.estadoPrivado) {
                window.history.back();
            } else {
                this.$root.$refs.CotizaForm.estadoFormCotizacion = 19;
            }

        },
        continuar() {
            this.$root.$refs.CotizaForm.estadoFormCotizacion = 21;
        },
        async guardarDocumentoFirmar(doc) {
            this.overlay = true;
            var res = false;
            var filetmp = false;
            if (doc == 5) {
                filetmp = this.chosenFile1;
            }
            if (doc == 6) {
                filetmp = this.chosenFile2;
            }
            var documento = {
                file: filetmp,
                seg_numero: this.seguro,
                cdo_tipo_docto: doc,
            };
            res = await fileservice.GuardaFirmado(documento);
            if (res.status == 200) {
                this.$alertas(res.data.estadoTransaccion.errors, "success");
                this.guardado = true;
                if (doc == 5) {
                    this.cargado1 = true;
                    this.fmandato = true;
                    this.dialog3 = false;
                }
                if (doc == 6) {
                    this.cargado2 = true;
                    this.frenuncia = true;
                    this.dialog4 = false;
                }
            } else {
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
                this.guardado = false;
            }
            this.overlay = false;
        },

        async preparaFirma(existe_cert) {
            this.dialog = false;
            this.dialogFirma = false;
            this.overlay = true;
            var res = false;
            var documento = {};
            if (this.doc_firma == 5) {
                documento = {
                    cdo_numero: [this.numero_mandato],
                };
            }
            if (this.doc_firma == 6) {
                documento = {
                    cdo_numero: [this.numero_renuncia],
                };
            }
            if (this.todo && this.activaRenuncia) {
                documento = {
                    cdo_numero: [this.numero_mandato, this.numero_renuncia],
                };
            }
            if (this.todo && !this.activaRenuncia) {
                documento = {
                    cdo_numero: [this.numero_mandato],
                };
            }

            this.$store.commit("settipodocfirma", this.doc_firma);
            if (existe_cert) {
                res = await datausuario.prefirma(documento);
                if (res.status == 200 && res.data.estadoTransaccion.success) {
                    console.log(res.data);
                    var firma = {
                        urlValidacion: res.data.Url_Validacion,
                        oid: res.data.oid,
                        exeCode: "",
                        seguro: this.seguro,
                    };

                    this.$store.commit("setfirmamandato", firma);
                    this.dialogFirma = false;
                    this.overlay = false;
                    window.open(res.data.Url_Validacion, "_self");
                } else {
                    this.$alertas("En este momento no podemos atender su solicitud", "warning");
                    this.overlay = false;
                }
            } else {
                var seguro = {
                    seg_numero: this.seguro,
                };
                res = await datausuario.certificado(seguro);
                if (res.status == 200 && res.data.estadoTransaccion.success) {
                    console.log(res.data);
                    var certificadoFirma = {
                        urlCertificado: res.data.Url_Certificado,
                    };

                    // this.$store.commit("setfirmamandato", firma);
                    this.dialogFirma = false;
                    this.overlay = false;
                    window.open(res.data.Url_Certificado, "_self");
                } else {
                    this.$alertas("En este momento no podemos atender su solicitud", "warning");
                    this.overlay = false;
                }
            }
            this.dialogFirma = false;
        },

        async firmarDocumento() {
            this.overlay = true;
            var res = false;
            var tipodoc = this.$store.state.tipoDocFirma;
            var documento = {
                oid: this.$store.state.firmaMandato.oid,
                exe_code: this.$store.state.firmaMandato.exeCode,
            };
            res = await datausuario.firmar(documento);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                console.log(res.data);
                this.$alertas("Firma existosa", "success");
                // var url_validacion=res.data.Url_Validacion;
                this.overlay = false;
            } else {
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
            }
        },

        async verMandato(opt) {
            this.overlay = true;
            var res = false;
            var mandato = false;
            var ext = "";
            var seguro = {
                seg_numero: this.seguro,
            };
            res = await datausuario.traeMandato(seguro);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                // this.$alertas(res.data.estadoTransaccion.errors, "success");
                mandato = res.data.cdo_docto;
                this.numero_mandato = res.data.cdo_numero;
                ext = res.data.cdo_extension.toLowerCase();
                this.extension = ext;
                this.mandato = mandato;
                this.$store.commit("setmandato", mandato);
                this.overlay = false;
                if (opt) {
                    this.dialog = true;
                }
                const binaryString = atob(mandato);
                const uint8Array = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([uint8Array], { type: "application/pdf" });

                this.chosenFile1 = blob;

                //   console.log(blob);
                //   const url = URL.createObjectURL(blob);
                //   const a = document.createElement("a");
                //   a.href = url;
                //   a.download = "prueba.pdf";
                //   document.body.appendChild(a);
                //   a.click();
                //   document.body.removeChild(a);
                //   URL.revokeObjectURL(url);
            } else {
                // this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
            }
        },
        async verMandatoMobile() {
            this.overlay = true;
            var res = false;
            var mandato = false;
            var ext = "";
            var seguro = {
                seg_numero: this.seguro,
            };
            res = await datausuario.traeMandato(seguro);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                // this.$alertas(res.data.estadoTransaccion.errors, "success");
                mandato = res.data.cdo_docto;
                this.numero_mandato = res.data.cdo_numero;
                ext = res.data.cdo_extension.toLowerCase();
                this.extension = ext;
                this.mandato = mandato;
                this.$store.commit("setmandato", mandato);
                this.overlay = false;
                // if (opt) {
                //   this.dialog = true;
                // }
                const binaryString = atob(mandato);
                const uint8Array = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([uint8Array], { type: "application/pdf" });

                this.chosenFile1 = blob;

                //   console.log(blob);
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "prueba.pdf";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            } else {
                // this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
            }
        },
        async verRenuncia(opt) {
            this.overlay = true;
            var res = false;
            var renuncia = false;
            var ext = "";
            var seguro = {
                seg_numero: this.seguro,
            };
            res = await datausuario.traeRenuncia(seguro);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                // this.$alertas(res.data.estadoTransaccion.errors, "success");
                renuncia = res.data.cdo_docto;
                this.numero_renuncia = res.data.cdo_numero;
                ext = res.data.cdo_extension.toLowerCase();
                this.extension = ext;
                this.renuncia = renuncia;
                this.$store.commit("setrenuncia", renuncia);
                this.overlay = false;
                if (opt) {
                    this.dialog2 = true;
                }
                const binaryString = atob(renuncia);
                const uint8Array = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([uint8Array], { type: "application/pdf" });

                this.chosenFile2 = blob;
            } else {
                // this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
            }
        },

        async firmartodo() {
            this.overlay = true;
            // this.guardarDocumentoFirmar(5);
            // this.guardarDocumentoFirmar(6);
            preparaFirma(0, 0);
            this.dialog5 = false;
            this.overlay = false;
        },
    },
    mounted() {
        this.verMandato(false);
        this.verRenuncia(false);
        var firmado = this.$store.state.estadoFirmas;
        this.firma = this.$store.state.firma;
        var estadomandato = this.$store.state.estadoMandato;
        var estadorenuncia = this.$store.state.estadoRenuncia;
        var estadoseguro = this.$store.state.estadoSeguro;
        if (estadomandato) {
            this.cargado1 = true;
            this.fmandato = true;
        } else {
            this.cargado1 = false;
            this.fmandato = false;
        }
        if (estadorenuncia) {
            this.cargado2 = true;
            this.frenuncia = true;
        } else {
            this.cargado2 = false;
            this.frenuncia = false;
        }
        if (firmado) {
            this.cargado1 = true;
            this.fmandato = true;
            this.cargado2 = true;
            this.frenuncia = true;
            this.guardado = true;
        } else {
            this.cargado1 = false;
            this.fmandato = false;
            this.cargado2 = false;
            this.frenuncia = false;
            this.guardado = false;
        }
        if (estadoseguro == 3) {
            this.activaRenuncia = true;
        } else {
            this.activaRenuncia = false;
        }
        if (this.$store.state.estadoFea > 1) {
            this.tipoFirma = false;
        }
        if (this.$store.state.estadoFea == 1) {
            this.tipoFirma = true;
        }

    },
};
</script>
<style scoped>
.colcenter {
    display: flex;
    justify-content: center;
}

.frame-32-2 {
    /* background: #d9d9d9; */
    border-radius: 80px;
    padding: 9px 20px 9px 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.line-1 {
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 100%;
    height: 0px;
    position: relative;
}

.para-gestionar-la-portabilidad-de-su-seguro-necesitamos-que-nos-firme-un-mandato-para-solicitar-la-renuncia-la-devoluci-n-de-la-prima-no-consumida-y-contratar-su-nuevo-seguro {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 18px);
    line-height: var(--headings-h5-line-height, 22px);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
    align-self: center;
}

.el-mandato-se-firma-electr-nicamente-por-lo-cual-ser-direccionado-un-sitio-seguro-para-verificaci-n-de-identidad-y-firma {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 18px);
    line-height: var(--headings-h5-line-height, 22px);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
}

.cambia-firma {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px);
    font-weight: var(--headings-h5-font-weight, 600);
    position: relative;
    width: 100%;
}

.vector4 {
    flex-shrink: 0;
    width: 379px;
    height: 222px;
    position: relative;
    overflow: visible;
    object-fit: cover;
}

.descarga {
    display: unset;
}

.descarga2 {
    display: none;
}

@media only screen and (max-width: 800px) {
    .sm-size {
        font-size: small !important;
    }

    .base-image-input {
        height: 150px !important;
    }

    .vector4 {
        width: 250px !important;
    }

    .descarga {
        display: none;
    }

    .descarga2 {
        display: unset;
    }
}
</style>