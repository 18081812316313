<template>
  <v-app>
    <div class="pageStyle">
      <Nav :titulo="`Bienvenido a MiPortalSeguro`" :fondo="true" :valores="false"></Nav>
      <div>

      </div>
      <div>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>

          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">

          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <v-row class="colcenter">
              <v-col cols="12" sm="10" md="8" lg="7" xl="6" class="colcenter formstyle">
                <div class="formulario-login">
                  <v-row class="colcenter">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="9" class="colcenter">
                      <div class="te-damos-la-bienvenida-por-favor-rellena-los-siguientes-datos">
                        Te damos la bienvenida,<br>por favor rellena los siguientes datos
                      </div>

                    </v-col>

                    <v-col cols="12" sm="12" md="11" lg="9" xl="9" class="colcenter">
                      <div class="line-1"></div>
                    </v-col>

                    <v-col cols="12" sm="12" md="11" lg="9" xl="9" class="colcenter"
                      >
                      <div>

                        <v-form ref="form" v-model="valid" autocomplete="off">

                          <v-row dense>
                            <v-col cols="12" sm="12">

                              <v-text-field v-model="rut" autocomplete="nope" :rules="rules" label="RUT" required @update:error="vrut"
                                color="rgba(0, 23, 135, 1)" outlined rounded>
                                <template v-slot:append>
                                  <div v-if="validrut == `no`"></div>
                                  <v-icon v-else-if="!validrut" color="m100" style="margin-bottom: 10px !important ;">
                                    mdi-checkbox-marked-circle
                                  </v-icon>
                                  <v-icon v-else-if="validrut" color="red" style="margin-bottom: 10px !important ;">
                                    mdi-close-circle
                                  </v-icon>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-text-field outlined autocomplete="new-password" rounded required color="rgba(0, 23, 135, 1)" v-model="password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="claveRules"
                                @update:error="vclave" :type="show1 ? 'text' : 'password'" name="input-10-1"
                                label="CLAVE" @click:append="show1 = !show1"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                              <v-btn text rounded class="btnTexto" @click="recuperaDia(true)">
                                <div class="recuperar-constrase-a">Recuperar constraseña</div>
                              </v-btn>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                              <v-btn text rounded class="btnTexto">
                                <div class="no-tienes-una-cuenta">¿No tienes una cuenta?</div>
                              </v-btn>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter mt-2">
                              <v-btn rounded x-large @click="validate" :disabled="!valid" color="#1d2eff"><span><span
                                    style="color:white;width: 180px;" class="submit">CONTINUAR</span></span></v-btn>

                            </v-col>
                          </v-row>

                        </v-form>
                      </div>




                    </v-col>



                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
            </v-row>

          </v-col>
        </v-row>
      </div>
      <Footer class=""></Footer>
      <Recupera ></Recupera>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </v-app>
</template>
<script>
import Nav from "../components/NavPrivado.vue";
import Footer from "../components/FooterPrivado.vue";
import Recupera from "./components/Recuperacion.vue";
import datausuario from "../../../services/usuario-datos";
import privado from "../../../services/privado";
import ActivaRecupera from "./ActivaRecupera.vue";

export default {
  name: "Frame2Loginnormal",
  components: {
    ActivaRecupera: ActivaRecupera,
    Nav,
    Footer,
    Recupera
  },
  data() {
    return {


      overlay: false,
      numeroIngreso: "",
      valid: false,
      SeguroDetalle: {},
      numeroRules: [
        (v) => !!v || "número requerido",
        (v) => (v >= 999) || "número inválido",
        (v) => (v <= 999999) || "número inválido",
      ],
      validnumero: false,
      bitacora: {
        bitacoraPlan: {
          spl_numero: 0,
          spl_seguro_numero: 0,
          spl_pln_numero: 0,
          cse_numero: 0,
          cse_ctt_numero: 0,
        },
        bitacoraPago: {
          estado_pago: 0,
          cce_transaccion: 0,
        },
      },
      dialogRec: false,
      datosContrato: [],

      rut: "",
      clave: "",
      validrut: "no",
      validclave: "no",
      claveRules: [
        v => !!v || 'el campo Clave es requerido',
        (v) => (v && v.length >= 4) || "largo mínimo 8 caracteres",

      ],
      show1: false,
      password: '',
      rulesPass: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 4 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    };
  },

  methods: {
    recuperaDia(estado){
      this.$root.$refs.RecuperaPassword.dialogRecupera = estado;
    },
    validate() {
      this.overlay = true;
      if (this.$refs.form.validate()) {

        this.login();
        return;
        setTimeout(() => {
          this.overlay = false;
          this.$store.commit("setislogin", true);
          console.log(this.$store.state.tipoPersonaEmpresa);
          if (this.$store.state.tipoPersonaEmpresa == "inicio") {
            this.$router.push("/home");
          } else {
            this.$router.push("/formulario-cotizacion");
            this.$store.commit("setestadoFormCotizacion", 6);
          }
        }, 1000);
      }

    },

    async login() {
      let res = false;
      let res2 = false;
      var vrut = this.rut.replace(/\./g, "");
      console.log(vrut);
      var data = {
        acc_rut: vrut,
        acc_clave: this.password
      };
      res = await datausuario.login(data);
      if (res.status == 200 && res.data.estadoTransaccion.success) {
        this.$store.commit("setloginestado", res.data.loginEstado);
        this.$store.commit("setloginusuario", res.data.loginUsuario);

        localStorage.setItem('loginEstado', JSON.stringify(res.data.loginEstado));
        localStorage.setItem('loginUsuario', JSON.stringify(res.data.loginUsuario));


        res2 = await privado.traeContrato(vrut);

        if (res2.status == 200) {
          this.$store.commit("setdatoscontrato", res2.data.datosContrato);
          this.datosContrato = res2.data.datosContrato;
          localStorage.setItem('datosContrato', JSON.stringify(res2.data.datosContrato));

          if (this.datosContrato.length > 1) {
            this.$router.push("/login2");
            return;
          }

          if (this.datosContrato.length == 1) {
            if (this.datosContrato[0].ctt_tipo_cliente == 2) {
              this.datosContrato[0].per_fec_nac = this.parseDate(this.datosContrato[0].per_fec_nac);
              this.$router.push("/login2");
              return;
            }
          }
        }
        var loginUsuario = this.$store.state.loginUsuario;
        loginUsuario.contrato = this.datosContrato[0];
        this.$store.commit("setloginusuario", loginUsuario);
        localStorage.setItem('loginUsuario', JSON.stringify(loginUsuario));
        this.$store.commit("setislogin", true);
        if (this.$store.state.estadoFormCotizacion == 5) {
          this.$store.commit("setselectmenu", 3);
        }
        this.$router.push("/mi-cuenta");
        // if(this.$store.state.tipoPersonaEmpresa=="inicio"){
        //         this.$router.push("/mi-cuenta");
        //       }else{
        //       this.$router.push("/formulario-cotizacion");
        //       this.$store.commit("setestadoFormCotizacion",6);
        //       }
      } else {
        this.$alertas("Usuario o clave inválida!", "error");
        this.overlay = false;
        return;
      }
      console.log(res);
    },

    vrut(v) {
      this.validrut = v;

    },
    vclave(v) {
      this.validclave = v;
    },
    volver() {
      this.$router.push("/home");
      // this.$router.go(-1)
    },
    parseDate: function (date) {
      this.dateCompara = date;
      if (!date) return null

      var arr = date.split('/'),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    validaRut: function (rutCompleto) {
      if (rutCompleto && rutCompleto != "") {
        rutCompleto = rutCompleto.replace(/\./g, '');
      }
      if (!rutCompleto) {
        this.rut = "";
      }
      if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      let rev = rut + "" + digv;
      let sw = true;
      let mem = false;
      for (let index = 0; index < rev.length; index++) {
        if (!index) {
          mem = rev.substring(index, 1);
          console.log("mem:" + mem);
        } else {
          if (mem != rev.substring(index, index + 1)) {
            sw = false;
            break;
          } else {
            console.log("err:" + rev.substring(index, 1));
          }
        }
      }
      if (rutCompleto == "44444444-4") {
        return true;
      }
      if (sw) { return false; }
      if (digv == "K") digv = "k";
      return this.dv(rut) == digv;
    },
    dv: function (T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },

    formateaRut(rut) {
      if (!rut || rut == "") {
        return;
      }
      var actual = rut.replace(/^0+/, "");
      if (actual != '' && actual.length > 0) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
          var letra = inicio.charAt(i);
          rutPuntos = letra + rutPuntos;
          if (j % 3 == 0 && j <= inicio.length - 1) {
            rutPuntos = "." + rutPuntos;
          }
          j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
      }
      return this.rut = rutPuntos;
    },
    largorut(rut, largo) {
      if (rut && rut.length > largo) {
        rut = rut.substring(0, rut.length - 1);
      }
      this.rut = rut.replace(/[^kK0-9.-]/gi, "");

      return true;
    },
  },
  computed: {
    rules() {
      const rules = [];
      let rule = "";
      rule = (v) => (v && v.length >= 11) || "largo mínimo 11 caracteres";
      rules.push(rule);
      rule = (v) => (v && this.largorut(v, 12)) || "largo máximo 12 caracteres";
      rules.push(rule);
      rule = (v) => !!v || "el rut es requerido";
      rules.push(rule);
      rule = (v) => this.validaRut(this.rut) || `rut inválido`;
      if (this.rut != "") {
        this.formateaRut(this.rut);
      }
      rules.push(rule);

      return rules;
    },
  },
  mounted() {
    if (!localStorage.getItem('loginEstado')) {
      this.isLogin = false;
      this.$store.commit("setislogin", false);

    } else {
      // this.$router.push("/mi-cuenta");
    }
  },
  metaInfo: {
    title: "exported project",
  },
};
</script>

<style scoped>
.colcenter {
  display: flex;
  justify-content: center;
}

.te-damos-la-bienvenida-por-favor-rellena-los-siguientes-datos {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  /* font-size: var(--headings-h4-font-size, 30px);
  line-height: var(--headings-h4-line-height, 30px); */
  font-size: clamp(0.8rem,5vw,1.6rem);
  font-weight: var(--headings-h4-font-weight, 400);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formulario-login {
  background: var(--blanco, #f0f2f2);
  border-radius: 40px;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.line-1 {
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px 0 0 0;
  flex-shrink: 0;
  width: 100%;
  height: 0px;
  position: relative;
}

.submit {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 13px);
  line-height: var(--text-line-height, 21px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  
}

.pageStyle {
  background-color: var(--blanco, #f0f2f2);
  height: 100%;
}

.recuperar-constrase-a {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-tienes-una-cuenta {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .dialogStyle{
  width: 100% !important;
}
}

.formstyle {
  padding: 20px;
}

.emergente-recuperar,
.emergente-recuperar * {
  box-sizing: border-box;
}

.emergente-recuperar {
  background: var(--blanco, #f0f2f2);
  border-radius: 40px;
  padding: 20px 80px 20px 80px;
  display: flex;
  flex-direction: row;
  gap: 100px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.ingrese-su-rut {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cotiza-form-rut {
  flex-shrink: 0;
  width: 764px;
  height: 60px;
  position: relative;
}

.rectangle-20 {
  background: var(--blanco, #f0f2f2);
  border-radius: 30px;
  border-style: solid;
  border-color: #a4aebc;
  border-width: 1.5px;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 0%;
}

.rut {
  color: #a4aebc;
  text-align: left;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  position: absolute;
  right: 55.65%;
  left: 4.3%;
  width: 40.05%;
  bottom: 16.45%;
  top: 16.88%;
  height: 66.67%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.env-e-su-rut-y-enseguida-le-enviaremos-un-correo-con-las-instrucciones-para-recuperar-su-contrase-a {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-2 {
  flex-shrink: 0;
  width: 800px;
  height: 0px;
  position: relative;
  overflow: visible;
}

.boton-salir {
  border-radius: 28px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 180px;
  height: 60px;
  position: relative;
}

.component-5 {
  background: var(--blanco, #f0f2f2);
  border-radius: 50px;
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px;
  padding: 12px 16px 12px 16px;
  flex-shrink: 0;
  width: 180px;
  height: 60px;
  position: relative;
}

.salir {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: absolute;
  left: 71px;
  top: 20px;
}

.boton-enviar {
  border-radius: 28px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 180px;
  height: 60px;
  position: relative;
}

.component-52 {
  background: var(--azul-primario, #1d2eff);
  border-radius: 30px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 180px;
  height: 60px;
  position: relative;
}

.submit {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  flex: 1;
}

.btnTexto {
  text-transform: unset !important;
  height: 20px !important;

}
.dialogStyle{
  /* background: var(--blanco, #f0f2f2); */
  background: var(--blanco, #f0f2f2);
    border-radius: 40px;
    padding: 20px 80px 20px 80px;
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    width: 50%;
}
.marcoDialog{
  background: var(--blanco, #f0f2f2);
  
  
}

.modalSize{

}
.modalDialog{
  display: flex;
  justify-content: center;
}
.footerMueve{
  position: fixed ;
  bottom: 0 ;
  width: 100% ;
}
@media only screen and (max-width: 1480px) {
  .footerMueve{
  position: relative !important;
}
}
</style>