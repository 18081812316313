<template>
    <v-row class="colcenter ">

        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="cuadros">
            <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                    <v-form ref="form" v-model="valid">
                        <v-container>
                            <v-row style="    display: flex;
justify-content: center;">
                               

                                <v-col cols="12" sm="12" class="colcenter">
                                    <div class="cuadro-terminos">
                                        <v-row>
                                            <v-col cols="12" sm="12"></v-col>
                                            <v-col cols="12" sm="12"></v-col>


                                            <v-col cols="12" sm="12" class="colcenter">
                                                <v-checkbox v-model="acepta" :rules="respRules" required
                                                    @update:error="vresp">
                                                    <template v-slot:label>
                                                        <div>
                                                            Acepto
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <a class="color-termininos" target="_blank"
                                                                        @click="leerTerminos(tipo)" v-on="on">
                                                                        términos y condiciones
                                                                    </a>
                                                                </template>
                                                                abrir en nueva ventana
                                                            </v-tooltip>
                                                            de MueveSeguro
                                                        </div>
                                                    </template>
                                                </v-checkbox>
                                            </v-col>


                                        </v-row>

                                    </div>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-form>
                </v-col>
                
            </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <div class="line-1"></div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <div class="frame-32-2">
                <v-row class="colcenter">
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                        <v-btn class="sm-size" outlined rounded x-large
                        @click="volver(tipo)"><span><span>REGRESAR</span></span></v-btn>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                        <v-btn class="sm-size" rounded x-large @click="validate(tipo)" color="#1d2eff"
                        :disabled="!valid"><span><span style="color:white">CONTINUAR</span></span></v-btn>
                    </v-col>
                </v-row>

            </div>
        </v-col>

        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-row>

</template>
<script>
import privado from "../../../../../services/privado";
import datausuario from "../../../../../services/usuario-datos";

export default {
    name: "TerminosCondiciones",
    components: {

    },
    data() {
        return {

            preguntas: [],
            respuestas: [],
            acepta: false,
            overlay: false,
            valid: false,
            respRules: [(v) => !!v || ""],
            validresp: true,
            dialogConfirma: false
        }
    },
    props: {
        renuncia: 0,
        tipo: "",
    },
    methods: {
        guardarFormulario() {
            // this.dialogConfirma = false
            // this.guardarDps()
        },
        vresp(v) {
            this.validresp = v;
        },
        async validate(tipo) {

            if (this.$refs.form.validate()) {
                if (tipo == "vigente") {
                    this.aceptaSeguro();
                } else {
                    this.continuaNuevo();
                }

            }
        },
        async continuaNuevo() {
            let res2 = false;
            this.overlay = true;
            res2 = await datausuario.traeplanSimulacionSeleccionado(this.$store.state.propuestaCreditoNuevo.spl_numero);
            if (res2.status == 200 && res2.data.estadoTransaccion.success) {
                console.log(res2.data.simulaSeguro);
                this.$root.$refs.ResumenSeguro.datosContrato = res2.data.simulaSeguro;
                this.$root.$refs.ResumenSeguro.datosContrato.spl_pln_fecha_inicio = res2.data.simulaSeguro.spl_pln_fecha_inicio;
                this.$root.$refs.ResumenSeguro.datosContrato.spl_pln_fecha_termino = res2.data.simulaSeguro.spl_pln_fecha_termino;
                this.$root.$refs.MediosPagos.datosContrato = this.$root.$refs.ResumenSeguro.datosContrato;

                this.$store.commit("setdatoscontratoseleccionado", this.$root.$refs.ResumenSeguro.datosContrato);

                this.overlay = false;
                this.$root.$refs.CotizaForm.estadoFormCotizacion = 22;
            } else {
                this.overlay = false;
                this.$root.$refs.CotizaForm.estadoFormCotizacion = 99;
            }

        },
        async leerTerminos(tipo) {
            this.overlay = true;
            var res = false;
            var documento = false;
            var ext = "";
            var nombreDoc = "";
            let seg_numero = "";
            if (tipo == "vigente") {
                seg_numero = this.$store.state.propuestaCreditoVigente.seg_numero
            } else {
                seg_numero = this.$store.state.propuestaCreditoNuevo.seg_numero
            }
            var seguro = {
                seg_numero: seg_numero
            }
            res = await datausuario.leerTerminos(seguro);

            if (res.status == 200 && res.data.estadoTransaccion.success) {
                // this.$alertas(res.data.estadoTransaccion.errors, "success");
                documento = res.data.cdo_docto;
                nombreDoc = res.data.cdo_nombre_docto;
                ext = res.data.cdo_extension.toLowerCase();

                const binaryString = atob(documento);
                const uint8Array = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([uint8Array], { type: "application/pdf" });

                console.log(blob);
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = nombreDoc;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                this.overlay = false;
            } else {
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
            }
        },
        revisa(value) {
            console.log(this.respuestas);
        },

        async aceptaSeguro() {
            let res = false;
            this.overlay = true;
          
            let seguro = {
                seg_numero: this.$store.state.propuestaCreditoVigente.seg_numero,
            }
            res = await privado.aceptarSeguro(seguro);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                this.overlay = false;
                this.$alertas("Seguro Aceptado", "success");
                this.$store.commit("setselectmenu", 3);
                // this.$router.push("/mi-cuenta");
                this.$root.$refs.CotizaForm.estadoFormCotizacion = 25;

            } else {
                this.$alertas("No se pudieron guardar los datos", "info");
            }
            this.overlay = false;
        },
        volver(tipo) {
            if (this.$store.state.estadoPrivado) {
                window.history.back();
            } else {
                if (tipo == "vigente") {
                    this.$root.$refs.CotizaForm.estadoFormCotizacion = 20;
                } else {
                    this.$root.$refs.CotizaForm.estadoFormCotizacion = 15;
                }
            }

        },
        continuar() {
            this.$root.$refs.CotizaForm.estadoFormCotizacion = 19;
        },
    },
    computed: {

    },
    mounted() {
        // this.traeDps()
    }
}
</script>
<style scoped>
.colcenter {
    display: flex;
    justify-content: center;
}

.frame-32-2 {
    /* background: #d9d9d9; */
    border-radius: 80px;
    padding: 9px 20px 9px 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.line-1 {
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 100%;
    height: 0px;
    position: relative;
}

@media only screen and (max-width: 800px) {
    .sm-size {
        font-size: small !important;
    }

    .base-image-input {
        height: 150px !important;
    }


}
</style>