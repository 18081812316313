
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'
import store from "./store";


import "../helpers/h-toast";
// import "../helpers/h-mask";
// import "./helpers/h-formulate";
// import "./helpers/h-sweetalert2";
// import "./helpers/h-loading-overlay";
// import "./helpers/h-tables";
// import "./helpers/h-toggle-button";
// import "./helpers/h-apexcharts";

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),  
}).$mount('#app')