import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_API_DATOS;

console.log("api: " + ENDPOINT_PATH);
export default {

  usuarios() {
    return ENDPOINT_PATH + "webapi.ldes/api/Persona/";
  },
  traeusuario(id) {
    return this.callApi(ENDPOINT_PATH + "Persona/"+id, "post");
  },
  
  traeusuarios() {
    return this.callApi(ENDPOINT_PATH + "usuarios", "post");
  },
  editar(id) {
    return this.callApi(
      ENDPOINT_PATH + "usuario/" + id + "/editar/usuario",
      "post"
    );
  },
  traeroles() {
    return this.callApi(ENDPOINT_PATH + "usuario/roles", "post");
  },
  estado(usuario) {
    return this.callApi(ENDPOINT_PATH + "usuario/estado", "post", usuario);
  },
  guardarsimulacion(usuario) {
    return this.callApi(ENDPOINT_PATH + "LineaDesgravamen/PropuestaCrear", "post",usuario);
  },
  
  simulacionSeguro(usuario) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/Simular", "post",usuario);
  },
  simulacionCreditoVigente(usuario) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/SimularCreditoVigente", "post",usuario);
  },
  simulacionCreditoNuevo(usuario) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/SimularCreditoNuevo", "post",usuario);
  },
  guardarsimulacionSeguro(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/ContratarPlan", "post",plan);
  },  
  guardarplanSeguro(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/PlanSeleccionar", "post",plan);
  },
  guardarsimulacionLog(bitacora) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/SeguroSeleccionar", "post",bitacora);
  },
  traeplanSimulacion(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/TerminoCondicionLeer/"+plan, "post");
  },
  traeplanSimulacionSeleccionado(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/PlanSimuladoLeer/"+plan, "post");
  },
  traeplanContratado(plan) {
    return this.callApi(ENDPOINT_PATH + "MisSeguros/SeguroLeer/"+plan, "post");
  },
  traeDocumentos(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/DocumentoConsultarEstados", "post",seguro);
  },
  traeMandato(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/MandatoLeer", "post",seguro);
  },
  traeRenuncia(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/SolicitudRenunciaLeer", "post",seguro);
  },
  guardarDocumento(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/DocumentoGrabar", "post",seguro);
  },
  listarDocumentos(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/BibliotecaListar", "post",seguro);
  },
  eliminarDocumento(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/DocumentoEliminar", "post",seguro);
  },
  descargaDocumentos(seguro){
    return this.callApi(ENDPOINT_PATH + "Documentos/BibliotecaLeer", "post",seguro);
  },
  traeTipoDocumentos(){
    return this.callApi(ENDPOINT_PATH + "Documentos/BibliotecaTiposListar", "post");    
  },
  contratar(seguro) {
    return this.callApi(ENDPOINT_PATH + "MisCotizaciones/SeguroContratar", "post",seguro);
  },
  traePreguntas() {
    return this.callApi(ENDPOINT_PATH + "Documentos/PreguntasListar", "post");
  },
  traePoliza(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/PolizaGenerar", "post",seguro);
  },
  traeComprobante(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/ComprobanteLeer", "post",seguro);
  },
  certificado(seguro) {
  return this.callApi(ENDPOINT_PATH + "FirmaElectronica/CertificadoSolicitar", "post",seguro);
  },
  prefirma(documento) {
    return this.callApi(ENDPOINT_PATH + "FirmaElectronica/PreFirmar", "post",documento);
  },
  firmar(documento) {
    return this.callApi(ENDPOINT_PATH + "FirmaElectronica/DocumentoFirmar", "post",documento);
  },
  
  
  traeMediosPagos(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/MedioPagosListar/"+plan, "post");
  },  
  creaTransaccion(data){
    return this.callApi(ENDPOINT_PATH + "Pagos/TransaccionCrear", "post",data);
  },
  confirmarTransaccion(data){
    return this.callApi(ENDPOINT_PATH + "Pagos/TransaccionConfirmar", "post",data);
  },
  guardarSimulacionContratar(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/PlanContratar", "post",plan);
  },
  leerTerminos(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/TerminosCondicionLeer", "post",seguro);
  },
  leerTerminosLinea(sim) {
    return this.callApi(ENDPOINT_PATH + "Documentos/TerminosCondicionLineaLeer", "post",sim);
  },
  leerPoliticaLinea(sim) {
    return this.callApi(ENDPOINT_PATH + "Documentos/PoliticaLineaLeer", "post",sim);
  },
  asistencia(cliente) {
    return this.callApi(ENDPOINT_PATH + "Persona/Asistencia", "post",cliente);
  },
  login(usuario) {
    return this.callApi(ENDPOINT_PATH + "Login/Ingreso", "post",usuario);
  },
  actualizar(usuario) {
    return this.callApi(
      ENDPOINT_PATH + "usuario/" + usuario.id + "/actualizar/usuario",
      "put",
      usuario
    );
  },
  eliminar(id) {
    return this.callApi(
      ENDPOINT_PATH + "usuario/" + id + "/eliminar",
      "delete"
    );
  },

  async callApi(ruta, method = false, data = false) {
    try {
      if (method == "post") {
        return await axios
          .post(ruta, data)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            let erdis = "";
            if (error.response.data.errors) {
              const er = error.response.data.errors;
              if (er.usuario) {
                erdis + " error usuario : ";
                er.usuario.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              if (er.nombre) {
                erdis + " error nombre : ";
                er.nombre.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              if (er.email) {
                erdis + " error email : ";
                er.email.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              if (er.password) {
                erdis = erdis + " error password : ";
                er.password.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              return { data: { success: false, message: erdis }, status: 200 };
            }
            return {
              data: { success: false, message: "Error no capturado" },
              status: 200,
            };
          });
      }
      if (method == "delete") {
        return await axios.delete(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "get") {
        return await axios.get(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "put") {
        return await axios
          .put(ruta, data)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      }
    } catch (error) {}
  },
};
