<template>
    <v-app class="tmpbase">
        <v-row class="bannerlogo2 rowpage2" style="display: flex; justify-content: center; ">
            <Nav class="nastyle" :titulo="`¡Activa tu cuenta!`" :fondo="true" :menuPrivadoCab="false">
            </Nav>
        </v-row>
        <v-row class="simulaPago">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-row class="colcenter ">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col>

                <v-col cols="11" sm="11" md="11" lg="8" xl="8" class="colcenter frame-60 ">
                    <v-row>
                        <v-col cols="10" sm="10" md="10" lg="10" xl="10" style="align-self: center;">
                            <div class="resumen-de-seguro">Datos Personales</div>

                        </v-col>
                        <v-col cols="2" sm="2" md="2" lg="2" xl="2">
                            <div class="colright">
                                <img class="vector6" src="/assets/privado/simulacionpagar/vector5.svg">
                            </div>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="11" sm="11" md="11" lg="8" xl="8" class="colcenter cuadroform frame-61">

                    <v-form ref="form" v-model="valid">
                        <v-container>
                            <v-row class="colcenter">

                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
                                    <span class="frame-activacindecuenta-text26">
                                        <span class="frame-activacindecuenta-text27">
                                            <span>1.- Te damos la bienvenida,</span>
                                            <br />
                                            <span></span>
                                        </span>
                                        <span>por favor rellena con tus datos personales:</span>
                                    </span>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">

                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-row dense>
                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-text-field v-model="formCuenta.nombre1" :rules="nombre1Rules"
                                                label="Primer nombre:" required class="nombre1 campotexto" single-line
                                                outlined @update:error="vnombre1" color="rgb(56,0,151)">
                                                <template v-slot:append>
                                                    <div v-if="validnombre1 == `no`"></div>
                                                    <v-icon v-else-if="!validnombre1" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validnombre1" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-text-field v-model="formCuenta.nombre2" :rules="nombre2Rules"
                                                label="Segundo nombre:" required class="nombre2 campotexto"
                                                maxlength="50" single-line outlined @update:error="vnombre2"
                                                color="rgb(56,0,151)">
                                                <template v-slot:append>
                                                    <div v-if="validnombre2 == `no`"></div>
                                                    <v-icon v-else-if="!validnombre2" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validnombre2" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-text-field v-model="formCuenta.apellido1" :rules="apellido1Rules"
                                                label="Apellido Paterno:" required class="apellido1 campotexto"
                                                single-line outlined @update:error="vapellido1" color="rgb(56,0,151)">
                                                <template v-slot:append>
                                                    <div v-if="validapellido1 == `no`"></div>
                                                    <v-icon v-else-if="!validapellido1" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validapellido1" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-text-field v-model="formCuenta.apellido2" :rules="apellido2Rules"
                                                label="Apellido Materno:" required class="apellido2 campotexto"
                                                single-line outlined @update:error="vapellido2" color="rgb(56,0,151)">
                                                <template v-slot:append>
                                                    <div v-if="validapellido2 == `no`"></div>
                                                    <v-icon v-else-if="!validapellido2" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validapellido2" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                            <v-text-field v-model="formCuenta.mail" :rules="mailRules" label="Mail:"
                                                required class="mail campotexto" single-line outlined
                                                @update:error="vmail" color="rgb(56,0,151)">
                                                <template v-slot:append>
                                                    <div v-if="validmail == `no`"></div>
                                                    <v-icon v-else-if="!validmail" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validmail" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                            <v-text-field v-model="formCuenta.celular" prefix="(+56) 9"
                                                :rules="celularRules" label="Celular:" required
                                                class="celular campotexto" single-line outlined @update:error="vcelular"
                                                color="rgb(56,0,151)">
                                                <template v-slot:append>
                                                    <div v-if="validcelular == `no`"></div>
                                                    <v-icon v-else-if="!validcelular" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validcelular" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                            <v-text-field v-model="formCuenta.direccion" :rules="direccionRules"
                                                label="Dirección" required class="direccion campotexto" single-line
                                                outlined @update:error="vdireccion" color="rgb(56,0,151)">
                                                <template v-slot:append>
                                                    <div v-if="validdireccion == `no`"></div>
                                                    <v-icon v-else-if="!validdireccion" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validdireccion" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-text-field v-model="formCuenta.numero" :rules="numeroRules"
                                                label="Número" required class="numero campotexto" single-line outlined
                                                @update:error="vnumero" color="rgb(56,0,151)">
                                                <template v-slot:append>
                                                    <div v-if="validnumero == `no`"></div>
                                                    <v-icon v-else-if="!validnumero" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validnumero" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-text-field v-model="formCuenta.depto" :rules="deptoRules" label="Depto.:"
                                                required class="depto campotexto" single-line outlined
                                                @update:error="vdepto" color="rgb(56,0,151)">
                                                <template v-slot:append>
                                                    <div v-if="validdepto == `no`"></div>
                                                    <v-icon v-else-if="!validdepto" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validdepto" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                            <v-select v-model="seleccionRegion" :items="regiones" required single-line
                                                outlined label="Región:" color="rgb(56,0,151)" :rules="regionRules"
                                                v-on:change="selectregion" style="cursor: pointer" class="campotexto">
                                                <template v-slot:append>
                                                    <div v-if="validregion == `no`"></div>
                                                    <v-icon color="rgba(0, 23, 135, 1)" v-else-if="validregion"
                                                        style="margin-bottom: 10px !important">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                </template>
                                            </v-select>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-select v-model="seleccionCiudad" :items="ciudades" required single-line
                                                outlined label="Ciudad:" color="rgb(56,0,151)" :rules="ciudadRules"
                                                v-on:change="selectciudad" style="cursor: pointer" class="campotexto">
                                                <template v-slot:append>
                                                    <div v-if="validciudad == `no`"></div>
                                                    <v-icon color="rgba(0, 23, 135, 1)" v-else-if="validciudad"
                                                        style="margin-bottom: 10px !important">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-select v-model="seleccionComuna" :items="comunas" required single-line
                                                outlined label="Comuna:" color="rgb(56,0,151)" :rules="comunaRules"
                                                v-on:change="selectcomuna" style="cursor: pointer" class="campotexto">
                                                <template v-slot:append>
                                                    <div v-if="validcomuna == `no`"></div>
                                                    <v-icon color="rgba(0, 23, 135, 1)" v-else-if="validcomuna"
                                                        style="margin-bottom: 10px !important">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-select v-model="seleccionNacionalidad" :items="paises.nombre" required
                                                single-line outlined class="campotexto" label="Nacionalidad"
                                                color="rgb(56,0,151)" :rules="nacionalidadRules"
                                                v-on:change="selectNacionalidad" style="cursor: pointer">
                                                <template v-slot:append>
                                                    <div v-if="validnacionalidad == `no`"></div>
                                                    <v-icon v-else-if="!validnacionalidad" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validnacionalidad" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-select v-model="seleccionGenero" :items="genero.nombre" required
                                                single-line outlined class="campotexto" label="Genero"
                                                color="rgb(56,0,151)" :rules="generoRules" v-on:change="selectGenero"
                                                style="cursor: pointer">
                                                <template v-slot:append>
                                                    <div v-if="validgenero == `no`"></div>
                                                    <v-icon v-else-if="!validgenero" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validgenero" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-select>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                            <v-select v-model="seleccionOcupacion" :items="ocupacion.nombre" required
                                                single-line outlined class="campotexto" label="Ocupación"
                                                color="rgb(56,0,151)" :rules="ocupacionRules"
                                                v-on:change="selectOcupacion" style="cursor: pointer">
                                                <template v-slot:append>
                                                    <div v-if="validocupacion == `no`"></div>
                                                    <v-icon v-else-if="!validocupacion" color="m100"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon v-else-if="validocupacion" color="red"
                                                        style="margin-bottom: 10px !important ;">
                                                        mdi-close-circle
                                                    </v-icon>
                                                </template>
                                            </v-select>
                                        </v-col>


                                    </v-row>


                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <span class="frame-activacindecuenta-text26">
                                        <span class="frame-activacindecuenta-text27">
                                            <span>2.- Ingresa tu nueva clave y no</span>
                                            <br />
                                            <span></span>
                                        </span>
                                        <span>la compartas con nadie</span>
                                    </span>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <div class="cuadroPass">
                                        <span class="frame-activacindecuenta-text47">
                                            <span class="frame-activacindecuenta-text49">
                                                <span>La contraseña debe ser <b>ALFANUMERICA</b></span>
                                            </span>
                                        </span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-row dense>
                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-text-field single-line outlined required color="rgba(0, 23, 135, 1)"
                                                autocomplete="new-password"
                                                class="campotexto" v-model="password"
                                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="claveRules"
                                                @update:error="vclave" :type="show1 ? 'text' : 'password'"
                                                name="input-10-1" label="Ingresar clave"
                                                @click:append="show1 = !show1"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                            <v-text-field single-line outlined required class="campotexto"
                                                color="rgba(0, 23, 135, 1)" v-model="password2"
                                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :rules="clave2Rules"
                                                @update:error="vclave2" :type="show2 ? 'text' : 'password'"
                                                name="input-10-1" label="Repite tu nueva clave"
                                                @click:append="show2 = !show2"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <div class="frame-32-2">
                                        <v-row class="colcenter">
                                            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                                <v-btn class="sm-size" outlined rounded x-large
                                                    @click="volver"><span><span>REGRESAR</span></span></v-btn>
                                            </v-col>
                                            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                                <v-btn class="sm-size" rounded x-large @click="validate" color="#1d2eff"
                                                    :disabled="!valid"><span><span
                                                            style="color:white">ACTIVAR</span></span></v-btn>
                                            </v-col>
                                        </v-row>

                                    </div>
                                </v-col>


                            </v-row>
                        </v-container>
                    </v-form>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
            </v-row>
        </v-col>
        </v-row>
        
        <Footer></Footer>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-app>

</template>
<script>
import datausuario from "../../../services/usuario-datos";
import privado from "../../../services/privado";
import listadoRegion from "../../../services/region";
import parametros from "../../../services/parametros";

import Nav from "../components/NavPrivado.vue";
import Footer from "../components/Footer.vue";

export default {
    name: "MiCuentaActiva",
    components: {
        Nav: Nav,
        Footer: Footer,
    },
    data() {
        return {
            overlay: false,
            valid: false,
            formCuenta: this.$store.state.formCuenta,
            regiones: [],
            comunasRegiones: this.$store.state.comunasRegiones,
            comunas: [],
            ciudades: [],
            seleccionRegion: "",
            seleccionComuna: "",
            seleccionCiudad: "",
            validnombre1: "no",
            validnombre2: "no",
            validapellido1: "no",
            validapellido2: "no",
            validmail: "no",
            validcelular: "no",
            validdireccion: "no",
            validdepto: "no",
            validnumero: "no",
            validregion: "no",
            validciudad: "no",
            validcomuna: "no",
            validnacionalidad: "no",
            validgenero: "no",
            validocupacion: "no",
            show1: false,
            show2: false,
            overlay: false,
            prefijoCliente: "9",
            seleccionNacionalidad: this.$store.state.seleccionNacionalidad,
            seleccionGenero: this.$store.state.seleccionGenero,
            seleccionOcupacion: this.$store.state.seleccionOcupacion,
            paises: { nombre: [], codigo: [] },
            genero: { nombre: [], codigo: [] },
            ocupacion: { nombre: [], codigo: [] },
            cargaRegiones: [],
            cargaCiudades: [],
            cargaComunas: [],
            nombre1Rules: [
                (v) => !!v || "el campo Primer nombre es requerido",
                (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
                (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
                (v) => (v && this.validarN1(v)) || "este campo solo acepta letras",
            ],
            nombre2Rules: [
                // (v) => !!v || "el campo Segundo nombre es requerido",
                // (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
                // (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
                (v) => (this.validarN2(v)) || "este campo solo acepta letras",
            ],
            apellido1Rules: [
                (v) => !!v || "el campo Apellido Paterno es requerido",
                (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
                (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
                (v) => (v && this.validarA1(v)) || "este campo solo acepta letras",
            ],
            apellido2Rules: [
                (v) => !!v || "el campo Apellido Materno es requerido",
                (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
                (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
                (v) => (v && this.validarA2(v)) || "este campo solo acepta letras",
            ],
            mailRules: [
                v => !!v || 'el campo Mail es requerido',
                (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
                v => /.+@.+\..+/.test(v) || 'Ingrese un Mail válido',
                (v) => (this.validaMail(v)) || "largo mínimo de dominio 3 caracteres",

            ],
            celularRules: [
                (v) => (this.formatPhoneNumber(v)) || "formato inválido",
                (v) => !!v || "el campo Celular es requerido",
                (v) => (v && v.length == 8) || "número de celular máximo 8 caracteres",
            ],
            direccionRules: [
                (v) => !!v || "el campo Dirección es requerido",
            ],
            deptoRules: [
                // (v) => !!v || "el campo Departamento es requerido",
            ],
            numeroRules: [
                (v) => !!v || "el campo Número es requerido",
            ],
            regionRules: [
                (v) => !!v || "el campo Región es requerido",
            ],
            ciudadRules: [
                (v) => !!v || "el campo Ciudad es requerido",
            ],
            comunaRules: [
                (v) => !!v || "el campo Comuna es requerido",
            ],
            validclave: "no",
            validclave2: "no",
            claveRules: [
                v => !!v || 'el campo Clave es requerido',
                (v) => (v && v.length >= 6) || "largo mínimo 6 caracteres",
                (v) => (v && v.length <= 10) || "largo máximo 10 caracteres",
                (v) => (v && v.match(/[a-zA-Z]/)) || "debe contener a lo menos una letra",
                (v) => (v && v.match(/[1-9]/)) || "debe contener a lo menos un número",
                // (v) => (v.includes(".") || v.includes("-") || v.includes("_") || v.includes("*") || v.includes(",") ) || "debe contener a lo menos un caracter especial",

            ],
            clave2Rules: [
                v => v && this.passIgual(v) || "La segunda clave ingresada no es idéntica a la primera, corrígela y vuelve a intentar.",
            ],
            nacionalidadRules: [
                (v) => !!v || "el campo nacionalidad es requerido",
                (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
            ],
            generoRules: [
                (v) => !!v || "el campo genero es requerido",
                (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
            ],
            ocupacionRules: [
                (v) => !!v || "el campo ocupación es requerido",
                (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
            ],
            password: '',
            password2: '',
            contrato: {},
        };
    },

    methods: {
        async traePaises() {
            let res = false;
            this.overlay = true;
            res = await parametros.traepaises();
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                // console.log("entra");
                let paises = {
                    nombre: [],
                    codigo: []
                };
                res.data.tablaPaises.forEach(element => {
                    paises.nombre.push(element.pai_nombre);
                    paises.codigo.push(element.pai_codigo);
                });
                // console.log(paises);
                // this.$root.$refs.LineaForm1.selectNacionalidad("CHILE");
                // this.$root.$refs.LineaForm1.seleccionNacionalidad="CHILE";
                // this.$root.$refs.LineaForm1.validnacionalidad=false;
                this.paises = paises;
                this.traeGenero();

                this.overlay = false;
                return;
            } else {
                this.$store.commit('setclienteencontrado', false);
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
            }
        },
        async traeGenero() {
            let res = false;
            res = await parametros.traegenero();
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                // console.log("entra");
                let genero = {
                    nombre: [],
                    codigo: []
                };
                res.data.tablaGenero.forEach(element => {
                    genero.nombre.push(element.sex_glosa);
                    genero.codigo.push(element.sex_numero);
                });
                // console.log(genero);
                this.genero = genero;
                this.traeOcupacion();

                return;
            } else {
                this.$store.commit('setclienteencontrado', false);
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
            }
        },
        async traeOcupacion() {
            let res = false;
            res = await parametros.traeocupaciones();
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                // console.log("entra");
                let ocupacion = {
                    nombre: [],
                    codigo: []
                };
                res.data.tablaOcupaciones.forEach(element => {
                    ocupacion.nombre.push(element.ocu_glosa);
                    ocupacion.codigo.push(element.ocu_numero);
                });
                console.log(ocupacion);
                this.ocupacion = ocupacion;
                this.traeCuenta(this.$route.params.token);
                return;
            } else {
                this.$store.commit('setclienteencontrado', false);
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
            }
        },
        vclave(v) {
            this.validclave = v;
        },
        selectNacionalidad(e) {
            this.validnacionalidad = false;
            this.$store.commit('setseleccionnacionalidad', e);
            let i = 0;
            let idx = 0;
            this.paises.nombre.forEach((element) => {
                if (element == e) {
                    idx = i;
                }
                i++;
            });
            this.formCuenta.nacionalidad = this.paises.codigo[idx];
        },
        selectGenero(e) {
            this.validgenero = false;
            this.$store.commit('setselecciongenero', e);
            let i = 0;
            let idx = 0;
            this.genero.nombre.forEach((element) => {
                if (element == e) {
                    idx = i;
                }
                i++;
            });
            this.formCuenta.genero = this.genero.codigo[idx];
        },
        selectOcupacion(e) {
            let i = 0;
            let idx = 0;
            this.$store.commit('setseleccionocupacion', e);
            this.ocupacion.nombre.forEach((element) => {
                if (element == e) {
                    idx = i;
                    this.validocupacion = false;
                }
                i++;
            });
            this.formCuenta.ocupacion = this.ocupacion.codigo[idx];
        },
        passIgual(val) {
            let resp = false;
            if (this.password == val) {
                resp = true;
            }
            console.log(val);
            return resp;
        },
        validarN1() {
            this.formCuenta.nombre1 = this.formCuenta.nombre1.replace(/[^a-zA-Z\s]/gi, "");
            this.formCuenta.nombre1 = this.formCuenta.nombre1.replace("  ", " ");
            return true;
        },
        validarN2() {
            this.formCuenta.nombre2 = this.formCuenta.nombre2.replace(/[^a-zA-Z\s]/gi, "");
            this.formCuenta.nombre2 = this.formCuenta.nombre2.replace("  ", " ");
            return true;
        },
        validarA1() {
            this.formCuenta.apellido1 = this.formCuenta.apellido1.replace(/[^a-zA-Z\s]/gi, "");
            this.formCuenta.apellido1 = this.formCuenta.apellido1.replace("  ", " ");
            return true;
        },
        validarA2() {
            this.formCuenta.apellido2 = this.formCuenta.apellido2.replace(/[^a-zA-Z\s]/gi, "");
            this.formCuenta.apellido2 = this.formCuenta.apellido2.replace("  ", " ");
            return true;
        },
        formatPhoneNumber(phone) {
            if (phone && phone != "") {
                let celular = "";
                phone = phone.replace("(+56) 9 ", "");
                phone = phone.replace("+569", "");
                if (phone && phone.length > 8) {
                    phone = phone.substring(0, phone.length - 1);
                }
                let letra = "";
                for (let index = 0; index < phone.length; index++) {
                    letra = phone.charAt(index).replace(/[^0-9]/gi, "");
                    celular = celular + letra;
                }
                this.formCuenta.celular = celular;
            }
            return true;
        },

        validaMail(email) {
            if (!email || email == "") {
                return;
            }
            var revmail = email.split("@");
            if (revmail.length == 2) {
                if (revmail[1].length >= 5) {
                    return true;
                }
                return false;
            }

        },
        async grabarCuenta() {
            this.overlay = true;
            let res = false;
            let res2 = false;
            var cel = "+569"+this.formCuenta.celular;
            cel = cel.replace("(", "");
            cel = cel.replace(")", "");
            cel = cel.replace(" ", "");
            cel = cel.replace(" ", "");
            if (this.formCuenta.depto == "") {
                this.formCuenta.depto = "0";
            }

            var cuenta = {
                per_tipo_cliente: this.contrato.per_tipo_cliente,
                perfilPersona: {
                    per_rut: this.contrato.per_rut,
                    per_dv: this.contrato.per_dv,
                    per_nombre_principal: this.formCuenta.nombre1,
                    per_nombre_segundo: this.formCuenta.nombre2,
                    per_ape_paterno: this.formCuenta.apellido1,
                    per_ape_materno: this.formCuenta.apellido2,
                    per_celular: cel,
                    per_fono: cel,
                    per_email: this.formCuenta.mail,
                    per_genero: this.formCuenta.genero,
                    per_pais: this.formCuenta.nacionalidad,
                    per_ocupacion: this.formCuenta.ocupacion,
                    acc_clave: this.password
                },
                perfilDireccion: {
                    dom_tipo_domicilio: this.contrato.per_tipo_cliente,
                    dom_origen: this.contrato.ctt_numero,
                    dom_calle: this.formCuenta.direccion,
                    dom_nro: this.formCuenta.numero,
                    dom_depto: this.formCuenta.depto,
                    dom_codigo_comuna: this.formCuenta.cocod,
                    dom_codigo_ciudad: this.formCuenta.cicod,
                    dom_codigo_region: this.formCuenta.rcod
                }
            };


            res = await privado.editarPerfil(cuenta);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                this.$alertas(res.data.estadoTransaccion.errors, "success");

                let loginUsuario = JSON.parse(localStorage.getItem('loginUsuario'));

       
                loginUsuario.acc_nombre_principal=this.formCuenta.nombre1;
                loginUsuario.acc_nombre_segundo=this.formCuenta.nombre2;
                loginUsuario.acc_ape_paterno=this.formCuenta.apellido1;
                loginUsuario.acc_ape_materno=this.formCuenta.apellido2;
                loginUsuario.contrato.per_pais=this.formCuenta.nacionalidad;
                loginUsuario.contrato.per_genero=this.formCuenta.genero;
            
                loginUsuario.contrato.per_email=this.formCuenta.mail;
                loginUsuario.contrato.per_celular=cel;
                loginUsuario.contrato.per_ocupacion=this.formCuenta.ocupacion;
            
                loginUsuario.contrato.per_nombre = this.formCuenta.nombre1 + " " + this.formCuenta.nombre2 + " " + this.formCuenta.apellido1 + " " + this.formCuenta.apellido2;
                loginUsuario.contrato.per_fono = cel;
                
                this.$store.commit("setloginusuario", loginUsuario);
                localStorage.setItem('loginUsuario', JSON.stringify(loginUsuario));

                var contrato = {
                    ctt_numero: this.contrato.ctt_numero
                };
                res2 = await privado.activarContrato(contrato);
                if (res2.status == 200 && res2.data.estadoTransaccion.success) {
                    this.$alertas(res2.data.estadoTransaccion.errors, "success");
                    this.$router.push('/login');
                    this.overlay = false;
                } else {
                    this.$alertas(res2.data.estadoTransaccion.errors, "error");
                    this.overlay = false;

                }
            } else {
                this.$alertas(res.data.estadoTransaccion.errors, "error");
                this.overlay = false;
            }
            this.overlay = false;

        },
        async traeCuenta(token) {
            //   this.overlay=true;
            let res = false;
            res = await privado.activaCuenta(token);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                var cel = res.data.tokenContrato.per_celular;
                if (res.data.tokenContrato.per_tipo_cliente == 2) {
                    this.prefijoCliente = "2";
                } else {
                    this.prefijoCliente = "9";
                }

                cel = cel.substring(4, 12);

                this.contrato = res.data.tokenContrato;
                this.formCuenta.nombre1 = res.data.tokenContrato.per_nombre_principal;
                this.formCuenta.nombre2 = res.data.tokenContrato.per_nombre_segundo;
                this.formCuenta.apellido1 = res.data.tokenContrato.per_ape_paterno;
                this.formCuenta.apellido2 = res.data.tokenContrato.per_ape_materno;
                this.formCuenta.mail = res.data.tokenContrato.per_email;
                this.formCuenta.celular = cel;
                this.formCuenta.direccion = res.data.tokenContrato.dom_calle;
                this.formCuenta.depto = res.data.tokenContrato.dom_depto;
                this.formCuenta.numero = res.data.tokenContrato.dom_nro;
                this.formCuenta.nacionalidad = res.data.tokenContrato.per_pais;
                this.formCuenta.genero = res.data.tokenContrato.per_genero;
                this.formCuenta.ocupacion = res.data.tokenContrato.per_ocupacion;
                this.seleccionRegion = res.data.tokenContrato.dom_glosa_region;
                this.seleccionComuna = res.data.tokenContrato.dom_glosa_comuna;
                this.seleccionCiudad = res.data.tokenContrato.dom_glosa_ciudad;
                this.selectregion(res.data.tokenContrato.dom_glosa_region);
                this.selectciudad(res.data.tokenContrato.dom_glosa_ciudad);
                this.selectcomuna(res.data.tokenContrato.dom_glosa_comuna);

                let i = 0;
                let idx = 0;
                this.genero.codigo.forEach((element) => {
                    if (element == res.data.tokenContrato.per_genero) {
                        idx = i;
                    }
                    i++;
                });
                this.seleccionGenero = this.genero.nombre[idx];
                this.$store.commit('setselecciongenero', this.seleccionGenero);
                this.validgenero = false;
                i = 0;
                idx = 0;
                this.paises.codigo.forEach((element) => {
                    if (element == res.data.tokenContrato.per_pais) {
                        idx = i;
                    }
                    i++;
                });
                this.seleccionNacionalidad = this.paises.nombre[idx];
                this.$store.commit('setseleccionnacionalidad', this.seleccionNacionalidad);
                this.validnacionalidad = false;
                i = 0;
                idx = 0;
                this.ocupacion.codigo.forEach((element) => {
                    if (element == res.data.tokenContrato.per_ocupacion) {
                        idx = i;
                    }
                    i++;
                });
                this.seleccionOcupacion = this.ocupacion.nombre[idx];
                this.$store.commit('setseleccionocupacion', this.seleccionOcupacion);
                this.validocupacion = false;

                // "tokenContrato": {
                // "ctt_numero": 29,
                // "per_rut": 10210776,
                // "per_dv": "4",
                // "per_rut_formato": "10.210.776-4",
                // "per_nombre": "ALBERTO PEREZ HERRERA",
                // "per_nombre_principal": "xime",
                // "per_nombre_segundo": "ANDREA",
                // "per_ape_paterno": "LILO",
                // "per_ape_materno": "LULU",
                // "per_email": "PEPE@APOLO.CL",
                // "per_celular": "+56971814644",
                // "per_tipo_cliente": 1,
                // "per_rut_representante": 10210776,
                // "dom_calle": null,
                // "dom_nro": null,
                // "dom_depto": null,
                // "dom_comuna": 0,
                // "dom_ciudad": 0,
                // "dom_region": 0,
                // "dom_glosa_comuna": null,
                // "dom_glosa_ciudad": null,
                // "dom_glosa_region": null
                // }
                // this.overlay=false;
            } else {
                //   this.$store.commit('setclienteencontrado', false);
                // this.overlay=false;
                this.$router.push('/home');
                //   this.$alertas("En este momento no podemos atender su solicitud","warning");
            }

        },
        async traeRegiones() {
            this.overlay = true;
            let res = false;
            res = await listadoRegion.traeregiones();
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                this.cargaRegiones = res.data.direccionCiudadComuna;
                res.data.direccionCiudadComuna.forEach(element => {
                    this.regiones.push(element.reg_descripcion);
                });
                this.traePaises();

                this.overlay = false;
            } else {
                //   this.$store.commit('setclienteencontrado', false);
                this.overlay = false;
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
            }

        },
        vclave(v) {
            this.validclave = v;
            this.password2 = "";
        },
        vclave2(v) {
            this.validclave2 = v;
        },
        vnombre1(v) {
            this.validnombre1 = v;
        },
        vnombre2(v) {
            this.validnombre2 = v;
        },
        vapellido1(v) {
            this.validapellido1 = v;
        },
        vapellido2(v) {
            this.validapellido2 = v;
        },
        vmail(v) {
            this.validmail = v;
        },
        vcelular(v) {
            this.validcelular = v;
        },
        vdireccion(v) {
            this.validdireccion = v;
        },
        vdepto(v) {
            this.validdepto = v;
        },
        vnumero(v) {
            this.validnumero = v;
        },
        vregion(v) {
            this.validcelular = v;
        },
        vciudad(v) {
            this.validciudad = v;
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.grabarCuenta();
            }
        },
        selectregion(e) {
            this.overlay = true;
            this.validregion = true;
            this.formCuenta.region = e;
            this.cargaCiudades = [];
            this.cargaComunas = [];
            this.ciudades = [];
            this.comunas = [];
            this.validciudad = "no",
                this.validcomuna = "no",
                // console.log(this.$store.state.nombreIntituciones);
                this.cargaRegiones.forEach((element) => {
                    if (element.reg_descripcion == e) {
                        this.formCuenta.rcod = element.reg_codigo;
                        this.cargaCiudades = element.ciudadUbicacion;
                        element.ciudadUbicacion.forEach(ciudad => {
                            this.ciudades.push(ciudad.ciu_descripcion);
                        });
                    }
                });
            this.overlay = false;
            //  this.comunas = comunas;
            //  this.ncod=ncod;
            //  this.$store.commit("setcomunas", comunas);
        },
        selectciudad(e) {
            this.overlay = true;
            this.validciudad = true;
            this.formCuenta.ciudad = e;
            this.cargaComunas = [];
            this.comunas = [];
            // console.log(this.$store.state.nombreIntituciones);
            this.cargaCiudades.forEach((element) => {
                if (element.ciu_descripcion == e) {
                    this.cargaComunas = element.comunasUbicacion;

                    this.formCuenta.cicod = element.ciu_numero;
                    element.comunasUbicacion.forEach(comuna => {
                        this.comunas.push(comuna.com_descripcion);
                    });
                }
            });
            this.overlay = false;
            //  this.comunas = comunas;
            //  this.ncod=ncod;
            //  this.$store.commit("setcomunas", comunas);
        },
        selectcomuna(e) {

            this.overlay = true;
            this.formCuenta.comuna = e;
            this.validcomuna = true;

            this.cargaComunas.forEach((element) => {
                if (element.com_descripcion == e) {
                    this.formCuenta.cocod = element.com_numero;
                }
            });
            this.overlay = false;

        },
        vrut(v) {
            this.validrut = v;
        },
        volver() {
            this.$router.push('/');
        },
    },
    computed: {

    },
    mounted() {
        console.log(this.$route.params);

    },
    created() {
        this.traeRegiones();

    },
    metaInfo: {
        title: "Activar Cuenta",
    },
};
</script>
<style scoped>
.nastyle {
    width: 100%;
}

.bannerlogo {
    background-image: url('/assets/privado/teaser-ms-02-10.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-left: 0px !important;
}

.bannerlogo2 {

    background-size: 100% auto;

}

.bannerlogo3 {

    background-size: 100% 100%;

}

@media only screen and (max-width: 1380px) {


    .bannerlogo {
        background-image: url('/assets/privado/teaser-ms-02-10.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-left: 0px !important;
    }
}

.tmpbase {

    background-color: rgba(232, 243, 255, 1) !important;
}

.cuadroform {
    padding: 20px;
}

.sm-size {
    width: 100%;
}

.cuadrodatosiz {
    align-self: center;
}

.divide {
    display: inline-flex;
}

.simulaPago {
    background-color: white;
}

.colcenter {
    display: flex;
    justify-content: center;
}

.colright {
    display: flex;
    justify-content: right;
}

.colleft {
    display: flex;
    justify-content: left;
}

.frame-60 {
    background: var(--azul-complementario, #04137f);
    border-radius: 40px 40px 0px 0px;
    padding: 20px 79px 20px 79px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;

}

.frame-61 {
    background: var(--blanco, #f0f2f2);
    border-radius: 0px 0px 40px 40px;
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px;
    /* padding: 40px; */
    display: flex;
    flex-direction: row;
    gap: 80px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.resumen-de-seguro {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.5rem);
    line-height: clamp(0.8rem, 4vw, 1.5rem);
    font-weight: var(--headings-h4-font-weight, 400);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.este-es-el-resumen-del-seguro-que-estas-contratando {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.7rem, 4vw, 1.3rem);
    line-height: clamp(0.8rem, 4vw, 1.5rem);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.vector6 {
    flex-shrink: 0;
    width: clamp(1.7rem, 4vw, 3.5rem);
    height: clamp(1.7rem, 4vw, 3.5rem);
    position: relative;
    overflow: visible;
}

.fecha-inicio {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    position: relative;
    font-size: clamp(0.5rem, 4vw, 0.8rem);
}

.fecha-inicio-span2 {
    color: var(--azul-complementario, #04137f);
    font-family: "CircularPro-Black", sans-serif;
    font-size: clamp(0.7rem, 4vw, 1rem);
    line-height: clamp(0.8rem, 4vw, 1.2rem);
    font-weight: 900;
}

.fechadetalle {
    background: var(--azul-claro, #e8f3ff);
    border-radius: 100px;
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px;
    width: 100%;
    /* height: 69px; */
    position: relative;
    padding: 3px 20px 3px 20px;

}

.textoiz {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.5rem);
    line-height: clamp(0.8rem, 4vw, 1.5rem);
    font-weight: var(--headings-h4-font-weight, 400);
    position: relative;
}

.textodr {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: "CircularPro-Black", sans-serif;
    font-size: clamp(0.8rem, 4vw, 1.5rem);
    line-height: clamp(0.8rem, 4vw, 1.4rem);
    font-weight: 900;
    position: relative;
}

.cuadro {
    padding: 20px;
    margin-top: 5px;
}

.image-2 {
    margin-top: 5px;
    border-radius: 40px;
    flex-shrink: 0;
    width: 80%;
    /* height: 418px; */
    position: relative;
    object-fit: cover;
}

.datos-personales {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
    /* font-size: var(--headings-h2-font-size, 48px);
    line-height: var(--headings-h2-line-height, 60px); */
    font-size: clamp(1rem, 4vw, 2.1rem);
    line-height: clamp(1rem, 4vw, 2.1rem);
    font-weight: var(--headings-h2-font-weight, 700);
    position: relative;
    width: 100%;
}

.line-1 {
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 100%;
    height: 0px;
    position: relative;
}

.datosiz {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: "CircularPro-Black", sans-serif;
    /* font-size: 24px;
    line-height: 28px; */
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3);
    font-weight: 900;
    position: relative;
    width: 100%;
    /* height: 28px; */
}

.datosdr {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px); */
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .divide {
        display: none !important;
    }

    .sm-size {
        font-size: small !important;
        width: 100%;
    }

    .cuadro {
        padding: 0px !important;
    }
}
</style>