import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Home from './views/Home.vue'
import HomeMo from './views/mobile/Home1.vue'
import CrearDegravamen from './views/linea/CrearDegravamenGrid.vue'
import CrearDegravamenMo from './views/mobile/linea/CrearDegravamenGrid.vue'
import CrearCotizacion from './views/cotizacion/CrearCotizacionGrid.vue'
import CrearCotizacionMo from './views/mobile/cotizacion/CrearCotizacionGrid.vue'
import FormularioCotizacion from './views/cotizacion/Formulario.vue'
// import FormularioCotizacionMo from './views/mobile/cotizacion/FormularioGrid.vue'
import FormularioLinea from './views/linea/FormularioGrid.vue'
import FormularioLineaMo from './views/mobile/linea/FormularioGrid.vue'
import HomePrivado2 from './views/privado/HomePrivado-old.vue'
import HomePrivado from './views/privado/HomePrivadoMo.vue'
import LoginMo from './views/login/loginMo.vue'
import Login2Mo from './views/login/login2Mo.vue'
import Recuperacion from './views/login/Recuperacion.vue'
import MiCuentaRec from './views/login/MiCuentaRec.vue'
import MiCuenta from './views/login/MiCuentaMo.vue'
import Contacto from './views/pages/Contacto.vue'
import NosotrosMo from './views/pages/NosotrosMo.vue'
import PreguntasMo from './views/pages/PreguntasMo.vue'

import Homepp from './views/Homepp.vue'
import HomeAppGrid from './views/HomeAppGrid.vue'



import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    // {
    //   name: 'Home',
    //   path: '/',
    //   component: Home,
    // },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'Home-mo',
      path: '/home',
      component: HomeMo,
    },
   {
      name: 'formulario-linea',
      path: '/formulario-linea',
      component: FormularioLineaMo,
    },  
     
    // {
    //   name: 'crear-degravamen',
    //   path: '/crear-degravamen',
    //   component: CrearDegravamen,
    // },
    // {
    //   name: 'formulario-linea',
    //   path: '/formulario-linea',
    //   component: FormularioLinea,
    // },
    // {
    //   name: 'formulario-linea-mo',
    //   path: '/m/formulario-linea',
    //   component: FormularioLineaMo,
    // },
    // {
    //   name: 'crear-cotizacion',
    //   path: '/crear-cotizacion',
    //   component: CrearCotizacion,
    // },
    // {
    //   name: 'formulario-cotizacion',
    //   path: '/formulario-cotizacion',
    //   component: FormularioCotizacion,
    // },
    {
      name: 'formulario-cotizacion',
      path: '/formulario-cotizacion',
      component: FormularioCotizacion,
    },
    {
      name: 'crear-degravamen',
      path: '/crear-degravamen',
      component: CrearDegravamenMo,
    },
    {
      name: 'crear-cotizacion',
      path: '/crear-cotizacion',
      component: CrearCotizacionMo,
    },
    // {
    //   name: 'listar-propuesta',
    //   path: '/listar-propuesta',
    //   component: ListarPropuesta,
    // },
    {
      name: 'login',
      path: '/login',
      component: LoginMo,
    },
    {
      name: 'login2',
      path: '/login2',
      component: Login2Mo,
    },
    {
      name: 'recuperacion',
      path: '/recuperacion',
      component: Recuperacion,
    },
    {
      name: 'mi-cuenta-recupera',
      path: '/mi-cuenta-recupera/:token',
      component: MiCuentaRec,
    },
    {
      name: 'mi-cuenta-activa',
      path: '/mi-cuenta-activa/:token',
      component: MiCuenta,
    },
    {
      name: 'mi-cuenta',
      path: '/mi-cuenta',
      component: HomePrivado,
    },
    // {
    //   name: 'mi-cuenta2',
    //   path: '/mi-cuenta2',
    //   component: HomePrivado2,
    // },
    // {
    //   name: 'mi-cuenta2',
    //   path: '/mi-cuenta2',
    //   component: HomePrivadoOld,
    // },
    {
      name: 'contacto',
      path: '/contacto',
      component: Contacto,
    },
    {
      name: 'nosotros',
      path: '/nosotros',
      component: NosotrosMo,
    },
    {
      name: 'preguntas',
      path: '/preguntas',
      component: PreguntasMo,
    },
    // {
    //   name: 'home1',
    //   path: '/m/home1',
    //   component: Homepp,
    // },
    // {
    //   name: 'crear-degravamen-mo',
    //   path: '/m/crear-degravamen',
    //   component: CrearDegravamenMo,
    // },
    // {
    //   name: 'crear-cotizacion-mo',
    //   path: '/m/crear-cotizacion',
    //   component: CrearCotizacionMo,
    // },
    // {
    //   name: 'home2',
    //   path: '/m/home2',
    //   component: HomeAppGrid,
    // },

    {
      path: '*',
      component: Home
    }
  ],
})
