<template>
    <div>
        <v-row class="colcenter tablafondo">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                <div class="selecciona-la-acci-n-indicada-en-el-bot-n-verde">
                    Selecciona la Acción indicada en el botón verde
                </div>
            </v-col>
            <v-col cols="11" sm="11" md="10" lg="9" xl="9" class="scroltab">
                <v-row class="scroltab">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="scroltab">
                        <v-form style="width: 100%;">
                            <v-simple-table class="sttable">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left actionFirst">
                                                <span class=""><span>Accion<v-icon>
                                                            mdi-swap-vertical
                                                        </v-icon></span></span>
                                            </th>
                                            <th class="text-left">
                                                <span class=""><span>N°
                                                        <v-icon @click="ordena(1)">
                                                            mdi-swap-vertical
                                                        </v-icon>
                                                    </span>
                                                </span>
                                            </th>

                                            <th class="text-left">
                                                <span class=""><span>Estado
                                                        <v-icon @click="ordena(2)">
                                                            mdi-swap-vertical
                                                        </v-icon>
                                                    </span>
                                                </span>
                                            </th>

                                            <th class="text-left">
                                                <span class=""><span>Plan <v-icon @click="ordena(3)">
                                                            mdi-swap-vertical
                                                        </v-icon></span></span>
                                            </th>
                                            <th class="text-left">
                                                <span class=""><span>Valor prima <v-icon @click="ordena(4)">
                                                            mdi-swap-vertical
                                                        </v-icon></span></span>
                                            </th>
                                            <th class="text-left">
                                                <span class=""><span>Monto asegurado <v-icon @click="ordena(5)">
                                                            mdi-swap-vertical
                                                        </v-icon></span></span>
                                            </th>
                                            <th class="text-left">
                                                <span class=""><span>Nombre Institución <v-icon @click="ordena(6)">
                                                            mdi-swap-vertical
                                                        </v-icon></span></span>
                                            </th>
                                            <th class="text-left actionLast">
                                                <span class=""><span>Acciones</span></span>
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr v-for="(item, index) in Cotizaciones" :key="index">
                                            <td class="actionFirst">
                                                <v-row class="colcenter">
                                                    <!-- <v-col cols="2" sm="2" class="grillbnt1">
                                                        <span class="group289170-text14"
                                                            @click="seguroDia(item, true)"><span><v-icon color="info"
                                                                    class="eyeicon">
                                                                    mdi-eye
                                                                </v-icon> </span> </span>

                                                    </v-col>
                                                    <v-col cols="2" sm="2">
                                                        <span class="custom-loader" @click="eliminaSimulacion(item.numero)" v-if="item.estado_seguro == 1">
                                                                <v-icon light> mdi-trash-can-outline</v-icon>
                                                        </span>
                                                    </v-col> -->
                                                    <v-col cols="12" sm="12" class="btn-col">

                                                        <v-btn block
                                                            v-if="item.spl_estado_cotizacion < 6 && item.estado_seguro == 1 && item.seg_usu_credito_nuevo == 0"
                                                            small class="btn-continuar" rounded color="success" dark
                                                            @click="continuaSeguro(item)">
                                                            Continuar
                                                            <template v-slot:loader>
                                                                <span class="custom-loader">
                                                                    <v-icon light>mdi-cached</v-icon>
                                                                </span>
                                                            </template>
                                                        </v-btn>
                                                        <v-btn block
                                                            v-if="item.spl_estado_cotizacion < 6 && item.estado_seguro == 1 && item.seg_usu_credito_nuevo == 1"
                                                            small class="btn-continuar" rounded color="info" dark
                                                            @click="continuaPagar(item)">
                                                            Pagar
                                                            <template v-slot:loader>
                                                                <span class="custom-loader">
                                                                    <v-icon light>mdi-cached</v-icon>
                                                                </span>
                                                            </template>
                                                        </v-btn>

                                                    </v-col>
                                                </v-row>


                                            </td>
                                            <td>
                                                <span class=""><span>{{ item.numero }}</span></span>
                                            </td>
                                            <td>
                                                <span class="">
                                                    <span>{{ item.segestado }}</span></span>

                                            </td>
                                            <td>
                                                <span class=""><span><b>{{ item.plan }}</b></span></span>
                                            </td>
                                            <td>
                                                <span class=""><span><b>{{ item.prima }}</b></span></span>
                                            </td>
                                            <td>
                                                <span class=""><span><b>{{ item.monto }}</b></span></span>
                                            </td>
                                            <td>
                                                <span class=""><span><b>{{ item.nombre
                                                            }}</b></span></span>
                                            </td>


                                            <td class="actionLast">

                                                <v-row>
                                                    <v-col cols="2" sm="2" class="grillbnt1">
                                                        <span class="group289170-text14"
                                                            @click="seguroDia(item, true)"><span><v-icon color="info"
                                                                    class="eyeicon">
                                                                    mdi-eye
                                                                </v-icon> </span> </span>
                                                    </v-col>
                                                    <v-col cols="2" sm="2" v-if="!item.seg_usu_credito_nuevo">
                                                        <span class="custom-loader"
                                                            @click="eliminaSimulacion(item.numero)"
                                                            v-if="item.estado_seguro == 1">
                                                            <v-icon light> mdi-trash-can-outline</v-icon>
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="2" sm="2" v-if="!item.seg_usu_credito_nuevo">
                                                        <span class="custom-loader"
                                                            @click="documentoSimulacion(item.numero)">
                                                            <v-icon light> mdi-file-document-edit-outline</v-icon>
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="6" sm="6" class="btn-col">
                                                        <span class="btn-span">
                                                            <v-btn
                                                                v-if="item.spl_estado_cotizacion < 6 && item.estado_seguro == 1 && item.seg_usu_credito_nuevo == 0"
                                                                small class="btn-continuar" rounded color="success" dark
                                                                @click="continuaSeguro(item)">
                                                                Continuar
                                                                <template v-slot:loader>
                                                                    <span class="custom-loader">
                                                                        <v-icon light>mdi-cached</v-icon>
                                                                    </span>
                                                                </template>
                                                            </v-btn>
                                                            <v-btn
                                                                v-if="item.spl_estado_cotizacion < 6 && item.estado_seguro == 1 && item.seg_usu_credito_nuevo == 1"
                                                                small class="btn-continuar" rounded color="info" dark
                                                                @click="continuaPagar(item)">
                                                                Pagar
                                                                <template v-slot:loader>
                                                                    <span class="custom-loader">
                                                                        <v-icon light>mdi-cached</v-icon>
                                                                    </span>
                                                                </template>
                                                            </v-btn>
                                                        </span>
                                                    </v-col>
                                                </v-row>


                                            </td>
                                        </tr>

                                        <tr v-if="!Cotizaciones.length">
                                            <td colspan="8">
                                                <span>No existen Cotizaciones</span>
                                            </td>
                                        </tr>

                                    </tbody>

                                </template>
                            </v-simple-table>
                        </v-form>
                    </v-col>
                </v-row>
            </v-col>


        </v-row>
        <v-row class="colcenter tablafondo">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                <v-pagination v-model="page" :length="paginas" :total-visible="5" circle :light="true" color="m100"
                    @input="cambiaPagina"></v-pagination>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                <div class="frame-32-2">
                    <v-row class="colcenter">
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                            <v-btn rounded large color="#04137f" @click="nuevoCalculo"><span><span
                                        style="color:white">NUEVO
                                        CALCULO</span></span></v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <DetalleSeguro :Seguro="seguroSimulacion"></DetalleSeguro>
        <BorrarSeguro :numeroSeguro="numeroSeguro"></BorrarSeguro>
        <ArchivosSeguro :seguro="numeroSeguro" :tipos="tipoDocumentos"></ArchivosSeguro>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import privado from "../../../../services/privado";
import datausuario from "../../../../services/usuario-datos";
import DetalleSeguro from "./components/DetalleSeguro.vue";
import BorrarSeguro from "./components/BorrarSeguro.vue";
import ArchivosSeguro from "./components/ArchivosSeguro.vue";

export default {
    name: "PrivadoCotizacion",
    components: {
        DetalleSeguro: DetalleSeguro,
        BorrarSeguro: BorrarSeguro,
        ArchivosSeguro: ArchivosSeguro
    },
    data() {
        return {
            overlay: false,
            page: 1,
            paginas: 0,
            orden: 1,
            Cotizaciones: [],
            CotizacionesOrigen: [],
            numeroCotizacion: "",
            o1: false,
            o2: false,
            o3: false,
            o4: false,
            o5: false,
            o6: false,
            respRules: [(v) => !!v || ""],
            validresp: true,
            valid: true,
            overlay: false,
            borrar: "",
            estadodemo: 3,
            seguroSimulacion: {},
            SeguroDetalle: {},
            numeroSeguro: 0,
            documentos: [],
            tipoDocumentos: []
        };
    },
    computed: {

    },
    props: {
        cotizacionEntrega: {}
    },
    methods: {
        async traeTipoDocumentos() {
            let res = false;
            this.overlay = true;
            res = await datausuario.traeTipoDocumentos();
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                res.data.tipoDoctos.forEach(element => {
                    this.tipoDocumentos.push({ nombre: element.tdo_glosa , valor : element.tdo_numero});
                });
                this.overlay = false;
            } else {
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
            }
        },
        async traeDocumentos(numero) {
            let res = false;
            this.overlay = true;
            this.documentos = [];
            let seguro = {
                seg_numero: numero,
                seg_tipo_docto: 0
            }
            res = await datausuario.listarDocumentos(seguro);
            if (res.status == 200 && res.data.estadoTransaccion.success) {

                this.documentos = res.data.bibliotecaDoctos;
                for (let index = 0; index < this.documentos.length; index++) {
                    this.documentos[index].cargado = true;
                    this.documentos[index].estadocargado = true;
                    this.documentos[index].chosenFile = this.documentos[index].doc_nombre_docto;
                }
                this.overlay = false;
                this.$root.$refs.ModalArchivosSeguro.dialogSeguro = true;
            } else {
                this.overlay = false;
                this.$root.$refs.ModalArchivosSeguro.dialogSeguro = true;
            }
            this.$root.$refs.ModalArchivosSeguro.documentos = this.documentos;
        },
        nuevoCalculo() {
            this.$root.$refs.HomePrivado.cambiaMenu(4);
        },
        eliminaSimulacion(numero) {
            this.numeroSeguro = numero;
            this.$root.$refs.ModalBorrarSeguro.dialogSeguroBorrar = true;
        },
        documentoSimulacion(numero) {
            this.numeroSeguro = numero;

            this.traeDocumentos(this.numeroSeguro);
        },
        seguroDia(item, estado) {
            this.seguroSimulacion = item;
            this.$root.$refs.ModalDetalleSeguro.dialogSeguro = estado;
        },
        async continuaPagar(seguro) {

            let res2 = false;
            this.overlay = true;
            res2 = await datausuario.traeplanSimulacionSeleccionado(seguro.numero_plan);
            if (res2.status == 200 && res2.data.estadoTransaccion.success) {
                this.SeguroDetalle = res2.data.simulaSeguro;

                //         "simulaSeguro": {
                // "spl_numero": 4359,
                // "spl_seg_numero": 5536,
                // "spl_pln_numero": 1,
                // "spl_pln_glosa": "Seguro de Desgravamen",
                // "spl_sim_monto_prima_cl": "$ 36.337",
                // "spl_sim_monto_prima_uf": "UF 0,97",
                // "spl_sim_monto_credito": "$ 3.580.000",
                // "spl_sim_saldo_insoluto": "$ 3.580.000",
                // "spl_pln_fecha_inicio": "07/07/2024",
                // "spl_pln_fecha_termino": "07/06/2027",
                // "spl_compania": "SAVE BCJ COMPAÑÍA DE SEGUROS DE VIDA S.A",
                // "spl_poliza": "POL 2 2013 1575",
                // "spl_nombre": "patricio ivan miranda herrera",
                // "spl_rut": "9.484.919-5",
                // "spl_celular": "+56956940671",
                // "spl_email": "pimiranda@apolo.cl",
                // "spl_calle": "VICTORIA VERGARA",
                // "spl_nro": "03063",
                // "spl_depto": null,
                // "spl_region": "Región de Atacama",
                // "spl_ciudad": "Caldera                  ",
                // "spl_comuna": "Caldera",
                // "spl_domicilio": "17",
                // "spl_estado": 1,
                // "spl_glosa_estado": "Pendiente",
                // "spl_valor_cuota": "$ 2.144"
                // }
                this.$root.$refs.HomePrivado.seguro = this.SeguroDetalle;
                this.$root.$refs.HomePrivado.cambiaMenu(14);
                this.overlay = false;

            } else {
                this.$alertas(res.data.estadoTransaccion.errors, "info");
                this.overlay = false;

            }

            // var res = false;
            // this.overlay = true;
            // res = await privado.traeDetalleSeguro(seguro.numero);

            // if (res.status == 200 && res.data.estadoTransaccion.success) {

            //     this.SeguroDetalle.numero = res.data.miSeguro.cse_numero;
            //     this.SeguroDetalle.poliza = res.data.miSeguro.cse_poliza;
            //     this.SeguroDetalle.transaccion = res.data.miSeguro.cse_transaccion;
            //     this.SeguroDetalle.inicio = res.data.miSeguro.cse_pln_fecha_inicio;
            //     this.SeguroDetalle.termino = res.data.miSeguro.cse_pln_fecha_termino;
            //     this.SeguroDetalle.celular = res.data.miSeguro.cse_celular;
            //     this.SeguroDetalle.email = res.data.miSeguro.cse_email;
            //     this.SeguroDetalle.direccion = res.data.miSeguro.cse_calle + " #" +
            //         res.data.miSeguro.cse_nro + ", " + res.data.miSeguro.cse_depto + ", " +
            //         res.data.miSeguro.cse_comuna + " " + res.data.miSeguro.cse_ciudad;
            //     this.SeguroDetalle.nombre = res.data.miSeguro.cse_nombre;
            //     this.SeguroDetalle.rut = res.data.miSeguro.cse_rut;
            //     this.SeguroDetalle.montoPeso = res.data.miSeguro.cse_sim_monto_prima_cl;
            //     this.SeguroDetalle.montoUf = res.data.miSeguro.cse_sim_monto_prima_uf;
            //     this.SeguroDetalle.montoAsegurado = res.data.miSeguro.cse_saldo_insoluto;
            //     this.SeguroDetalle.tipoInstitucion = res.data.miSeguro.cse_glosa_tipo_institucion;
            //     this.SeguroDetalle.nombreIntitucion = res.data.miSeguro.cse_glosa_institucion;
            //     this.SeguroDetalle.saldoInsoluto = res.data.miSeguro.cse_sim_monto_credito;
            //     this.SeguroDetalle.mesesPendientePago = res.data.miSeguro.cse_meses_pendiente;
            //     this.SeguroDetalle.periodoGracia = res.data.miSeguro.cse_periodo_gracia;
            //     this.SeguroDetalle.plazoCredito = res.data.miSeguro.cse_plazo_meses;
            //     this.SeguroDetalle.cantidadCuotas = res.data.miSeguro.cse_meses_pendiente;
            //     this.SeguroDetalle.spl_numero = res.data.miSeguro.spl_numero;
            //     this.SeguroDetalle.spl_seguro_numero = res.data.miSeguro.spl_seguro_numero;
            //     this.SeguroDetalle.cse_pln_numero = res.data.miSeguro.cse_pln_numero;
            //     this.SeguroDetalle.cse_numero = res.data.miSeguro.cse_numero;
            //     this.SeguroDetalle.cse_ctt_numero = res.data.miSeguro.cse_ctt_numero;
            //     this.SeguroDetalle.cse_seg_numero = res.data.miSeguro.cse_seg_numero;
            //     this.SeguroDetalle.cse_usu_credito_nuevo = res.data.miSeguro.cse_usu_credito_nuevo;
            //     this.overlay = false;
            //     this.$root.$refs.HomePrivado.seguro = this.SeguroDetalle;
            //     this.$root.$refs.HomePrivado.cambiaMenu(14);
            // } else {
            //     this.$alertas(res.data.estadoTransaccion.errors, "info");
            //     this.overlay = false;
            // }



        },
        continuaSeguro(seguro) {

            this.$store.commit("setseguroseleccion", seguro.numero);
            this.$store.commit("setplanseleccion", seguro.numero_plan);
            this.$root.$refs.HomePrivado.overlay = true;
            this.$store.commit("setestadoprivado", true);
            let opcion = 0;
            this.$store.commit("setestadofea", seguro.spl_estado_fea);
           
            if (seguro.spl_estado_cotizacion == 1) {
                opcion = 17;
            }
            if (seguro.spl_estado_cotizacion == 2) {
                opcion = 18;
            }
            if (seguro.spl_estado_cotizacion == 3) {
                opcion = 20;
                this.$store.commit("setfirma", seguro.cdo_estado_firma);
            }
            if (seguro.spl_estado_cotizacion == 4) {
                opcion = 23;
            }
            if (seguro.spl_estado_cotizacion == 5) {
                opcion = 21;
            }
            if (seguro.seg_usu_credito_nuevo == 0) {
                this.$store.commit("settipocotizacion", "vigente");
                this.$store.commit("setpropuestacreditovigente", { spl_numero: seguro.numero_plan, spl_pln_numero: seguro.spl_pln_numero, seg_numero: seguro.numero });
            } else {
                opcion = 21;
                this.$store.commit("setpropuestacreditonuevo", { spl_numero: seguro.numero_plan, spl_pln_numero: seguro.spl_pln_numero, spl_seg_numero: seguro.numero });
                this.$store.commit("settipocotizacion", "nuevo");
            }
            this.$store.commit("setestadoFormCotizacionPrivado", opcion);

            this.$router.push("/formulario-cotizacion");
        },
        cambiaestados(seguro, op) {
            this.overlay = true;
            this.$store.commit("setestadoseguro", seguro.estado_seguro);
            this.continuaImg(seguro.numero, seguro.cdo_ci_frente_estado_carga, op);
            this.continuaDoc(seguro.numero, seguro.cdo_prepago_estado_carga, seguro.cdo_resumen_credito_estado_carga, op);
            this.continuaFirma(seguro.numero, seguro.cdo_mandato_estado_carga, seguro.cdo_renuncia_estado_carga, op);

            if (op == 4) {
                this.continuaContrato(seguro.numero, seguro.numero_plan);
            }

        },
        cambiaestadosnuevo(seguro, op) {
            this.overlay = true;

            if (op == 5) {
                this.continuaContratonuevo(seguro.numero, seguro.numero_plan, seguro.spl_pln_numero);
            }

        },
        continuaImg(nro, estado, op) {
         
            this.$store.commit("setseguroseleccion", nro);
            this.$store.commit("setestadoidentidad", estado);

            this.$root.$refs.HomePrivado.overlay = true;
            this.$store.commit("setestadoprivado", true);
            if (op == 1) {
                this.$store.commit("setestadoFormCotizacionPrivado", 17);
                this.$router.push("/formulario-cotizacion");
            }

        },
        continuaDoc(nro, estadop, estador, op) {
            this.$store.commit("setseguroseleccion", nro);

            if (estador > 0) {
                this.$store.commit("setestadoprepago", 1);
            } else {
                this.$store.commit("setestadoprepago", 0);
            }
            if (estadop > 0) {
                this.$store.commit("setestadocreditocarga", 1);
            } else {
                this.$store.commit("setestadocreditocarga", 0);
            }

            if (estadop > 0 && estador > 0) {
                this.$store.commit("setestadodocumentos", 1);
            } else {
                this.$store.commit("setestadodocumentos", 0);
            }
            this.$store.commit("setestadoprivado", true);
            this.$root.$refs.HomePrivado.overlay = true;
            if (op == 2) {
                this.$store.commit("setestadoFormCotizacionPrivado", 19);
                this.$router.push("/formulario-cotizacion");
            }

        },
        continuaFirma(nro, estadom, estador, op) {
            this.$store.commit("setseguroseleccion", nro);
            if (estadom > 0) {
                this.$store.commit("setestadomandato", 1);
            } else {
                this.$store.commit("setestadomandato", 0);
            }
            if (estador > 0) {
                this.$store.commit("setestadorenuncia", 1);
            } else {
                this.$store.commit("setestadorenuncia", 0);
            }
            if (estadom > 0 && estador > 0) {
                this.$store.commit("setestadofirmas", 1);
            } else {
                this.$store.commit("setestadofirmas", 0);
            }

            this.$store.commit("setestadoprivado", true);
            this.$root.$refs.HomePrivado.overlay = true;
            if (op == 3) {
                this.$store.commit("setestadoFormCotizacionPrivado", 20);
                this.$router.push("/formulario-cotizacion");
            }

        },
        continuaContrato(nro, plan) {
            this.$store.commit("setseguroseleccion", nro);
            this.$store.commit("setplanseleccion", plan);
            this.$store.commit("setestadoprivado", true);
            this.$root.$refs.HomePrivado.overlay = true;
            this.$store.commit("setestadoFormCotizacionPrivado", 21);

            this.$router.push("/formulario-cotizacion");
        },
        continuaContratonuevo(nro, plan, pln) {
            this.$store.commit("setseguroseleccion", nro);
            this.$store.commit("setplanseleccion", plan);
            this.$store.commit("setpropuestacreditonuevo", { spl_numero: plan, spl_pln_numero: pln, spl_seg_numero: nro });
            this.$store.commit("setestadoprivado", true);
            this.$root.$refs.HomePrivado.overlay = true;
            this.$store.commit("setestadoFormCotizacionPrivado", 21);
            this.$store.commit("settipocotizacion", "nuevo");

            this.$router.push("/formulario-cotizacion");
        },
        agregarProducto() {
            let carro = [];
            this.Cotizaciones.forEach(element => {
                if (element.seleccion) {
                    carro.push(element);
                }
            });
         
            this.$store.commit("setcotizacionescarro", carro);
        },
        vresp(v) {
            this.validresp = v;
        },
        validate() {
            let segurosSelect = false;
            if (this.$refs.form.validate()) {
                this.Cotizaciones.forEach(element => {
                    if (element.seleccion) {
                        segurosSelect = true;
                    }
                });
                if (!segurosSelect) {
                    this.$alertas("escoja una cotización", "info");
                    return;
                }
                this.$store.commit("setestadoFormCotizacionPrivado", 13);
                this.$router.push("/formulario-cotizacion");
            }
        },
        async cargaPlanes(seguro = false) {
            this.overlay = true;
            var res = false;
            var simu = this.numeroCotizacion.split("-")[0];
            if (seguro) {
                simu = seguro;
            }

            res = await privado.traePlanSimulacion(simu);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                for (let index = 0; index < res.data.parrillaSeguro.length; index++) {
                    res.data.parrillaSeguro[index].seleccion = false;
                    res.data.parrillaSeguro[index].comparar = false;
                    res.data.parrillaSeguro[index].terminos = false;
                }
                let seg = {
                    simulacionSeguro: res.data.simulacionSeguro,
                    parrillaSimulacion: res.data.parrillaSeguro
                }
                this.overlay = false;
                this.$store.commit("setseg", seg);
                this.$store.commit("setestadoFormCotizacionPrivado", 5);
                this.$router.push("/formulario-cotizacion");
            } else {
                this.$alertas(res.data.estadoTransaccion.errors, "info");
                this.overlay = false;
            }
        },
        cambiaPagina(value) {
            this.Paginacion(value);
        },
        actualizaDatos() {
            this.CotizacionesOrigen = this.$root.$refs.HomePrivado.misCotizaciones;
           
            if (!this.CotizacionesOrigen.length) {
                this.Cotizaciones = [];
                this.page = 0;
                this.paginas = 0;
            } else {
                this.Paginacion(this.page);
            }
           
        },
        ordena(campo) {
            this.$root.$refs.HomePrivado.overlay = true;
            setTimeout(() => {
                this.orden = campo;
                this.CotizacionesOrigen.sort((a, b) => {
                    if (campo == 1) {
                        if (this.o1) {
                            this.o1 = false;

                            if (a.numero > b.numero) { return 1; }
                            if (a.numero < b.numero) { return -1; }
                            return 0;

                        } else {
                            this.o1 = true;

                            if (a.numero < b.numero) { return -1; }
                            if (a.numero > b.numero) { return 1; }
                            return 0;

                        }
                    }
                    if (campo == 2) {
                        if (this.o2) {
                            this.o2 = false;
                            if (a.segestado.toLowerCase() > b.segestado.toLowerCase()) { return -1; }
                            if (a.segestado.toLowerCase() < b.segestado.toLowerCase()) { return 1; }
                            return 0;
                        } else {
                            this.o2 = true;
                            if (a.segestado.toLowerCase() < b.segestado.toLowerCase()) { return -1; }
                            if (a.segestado.toLowerCase() > b.segestado.toLowerCase()) { return 1; }
                            return 0;
                        }
                    }
                    if (campo == 3) {
                        if (this.o2) {
                            this.o2 = false;
                            if (a.plan.toLowerCase() > b.plan.toLowerCase()) { return -1; }
                            if (a.plan.toLowerCase() < b.plan.toLowerCase()) { return 1; }
                            return 0;
                        } else {
                            this.o2 = true;
                            if (a.plan.toLowerCase() < b.plan.toLowerCase()) { return -1; }
                            if (a.plan.toLowerCase() > b.plan.toLowerCase()) { return 1; }
                            return 0;
                        }
                    }
                    if (campo == 4) {
                        if (this.o3) {
                            this.o3 = false;
                            if (a.prima.toLowerCase() > b.prima.toLowerCase()) { return -1; }
                            if (a.prima.toLowerCase() < b.prima.toLowerCase()) { return 1; }
                            return 0;
                        } else {
                            this.o3 = true;
                            if (a.prima.toLowerCase() < b.prima.toLowerCase()) { return -1; }
                            if (a.prima.toLowerCase() > b.prima.toLowerCase()) { return 1; }
                            return 0;
                        }
                    }
                    if (campo == 5) {
                        if (this.o4) {
                            this.o4 = false;
                            if (a.monto.toLowerCase() > b.monto.toLowerCase()) { return -1; }
                            if (a.monto.toLowerCase() < b.monto.toLowerCase()) { return 1; }
                            return 0;
                        } else {
                            this.o4 = true;
                            if (a.monto.toLowerCase() < b.monto.toLowerCase()) { return -1; }
                            if (a.monto.toLowerCase() > b.monto.toLowerCase()) { return 1; }
                            return 0;
                        }
                    }
                    if (campo == 6) {
                        if (this.o5) {
                            this.o5 = false;
                            if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) { return -1; }
                            if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) { return 1; }
                            return 0;
                        } else {
                            this.o5 = true;
                            if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) { return -1; }
                            if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) { return 1; }
                            return 0;
                        }
                    }


                });
                this.Paginacion(this.page)
                this.$root.$refs.HomePrivado.overlay = false;
            }, 300);
        },
        Paginacion(np) {
            np--;
            this.$root.$refs.HomePrivado.overlay = true;
            setTimeout(() => {
                var cotizaciones2 = this.CotizacionesOrigen;
                this.Cotizaciones = [];
                this.paginas = 0;
                var reg = cotizaciones2.length;
                var rpage = reg % 5;
                var paginas = Math.trunc(reg / 5);
                if (rpage) {
                    paginas++;
                }
                this.paginas = paginas;
                if (np >= this.paginas) {
                    this.page = np;
                    np--;
                }
                var ini = 5 * np;
                var corte = 5 + ini - 1;

                for (let i = ini; i < cotizaciones2.length; i++) {
                    if (i > corte) {
                        break;
                    }
                    this.Cotizaciones.push(cotizaciones2[i]);
                }
               
                this.$root.$refs.HomePrivado.overlay = false;
            }, 500);
        }
    },
    mounted() {
        if (!this.CotizacionesOrigen.length) {
            this.Cotizaciones = [];
            this.page = 0;
            this.paginas = 0;
        } else {
            this.Paginacion(1);
        }
        window.scrollTo(0, 0);
        this.traeTipoDocumentos();
        // var styleElement = document.createElement("style");
        // styleElement.appendChild(document.createTextNode("div ::-webkit-scrollbar {-webkit-appearance: none;width: 20px;}div ::-webkit-scrollbar-thumb {border-radius: 10px;background-color: rgba(113, 48, 237, 1);-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);}"));
        // document.getElementsByTagName("head")[0].appendChild(styleElement);
    },
    created() {
        this.CotizacionesOrigen = this.$root.$refs.HomePrivado.misCotizaciones;

        this.$root.$refs.PrivadoCotizacion = this;
    },

};
</script>
<style scoped>
.custom-loader {
    cursor: pointer;
}

.grillbnt1 {
    align-self: center !important;
}

.eyeicon {
    font-size: 25px;
    cursor: pointer;
}

.scroltab {
    display: flex;
    justify-content: center;
}

.tablafondo {
    background-color: white;
}

.sttable {
    border-radius: 20px;
    border: 1px solid #04137f;
    overflow: hidden;
    /* overflow-x: scroll; */
}

.colcenter {
    display: flex;
    justify-content: center;
}

.selecciona-la-acci-n-indicada-en-el-bot-n-verde {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

@media only screen and (max-width: 800px) {
    .scroltab {
        overflow-x: scroll !important;
        display: table-cell !important;
    }

    .actionLast {
        display: none !important;
    }

    .actionFirst {
        display: table-cell !important;
    }
}

.actionLast {
    display: table-cell !important;
}

.actionFirst {
    display: none;
}

.btn-col {
    align-self: center;
}
</style>